/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
@media (max-width: 1023px) {
  .pagesidebar {
    margin-top: 60px;
  }
  .ttresource-sidebar__meta {
    margin-top: 30px;
  }
  .page--withsidebar .pagesidebar {
    grid-column: var(--pagegrid-col-default);
  }
}
/* Metadata at the right of the page content */
@media (min-width: 1024px) {
  .page--withsidebar {
    /*
        .page--withsidebar .page__contentarea > p
      , .page--withsidebar .page__contentarea > ul
      , .page--withsidebar .page__contentarea > ol
      , .page--withsidebar .page__contentarea > h2
      , .page--withsidebar .page__contentarea > h3
      , .page--withsidebar .page__contentarea > h4
      , .page--withsidebar .widget-downloadsandlinks
    */
  }
  .page--withsidebar .page-header--basic {
    --pagegrid-col-default: var(--pagegrid-col-wide);
  }
  .page--withsidebar .page__contentarea {
    --pagegrid-col-default: 6 / span 12;
    --pagegrid-col-narrow: 6 / span 12;
    --pagegrid-col-wide: 6 / span 12;
  }
  .page--withsidebar .widget-accordion__item .widget--persons {
    padding-left: 15px;
    padding-right: 15px;
    --pagegrid-col-narrow: (--pagegrid-col-verywide);
  }
  .page--withsidebar .pagesidebar {
    margin-top: var(--header-to-content-margin);
    grid-column: 19/span 6;
    grid-row: 2/span 100;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .page--withsidebar .page__contentarea > * {
    max-width: calc(100% - 30px);
  }
  .page--withsidebar .page__contentarea > .widget-cta,
  .page--withsidebar .page__contentarea > .widget-ctablocks,
  .page--withsidebar .page__contentarea > .widget-cblblocks,
  .page--withsidebar .page__contentarea > .widget-news,
  .page--withsidebar .page__contentarea > .widget--persons,
  .page--withsidebar .page__contentarea > .widget--projects,
  .page--withsidebar .page__contentarea > .widget--richcontent {
    max-width: none;
  }
  .page--withsidebar .page--toolkit-resource .pagesidebar {
    grid-row: 2/span 100;
  }
  .page--withsidebar .widget-accordion {
    grid-column: var(--pagegrid-col-default);
    --pagegrid-coldef-sidemargin: 0;
    --pagegrid-coldef-gutteroutside: 0;
    --pagegrid-template-columns:
        var(--pagegrid-coldef-sidemargin)
        var(--pagegrid-coldef-gutteroutside)
        repeat(calc( var(--pagegrid-columns) - 1), minmax(0, 100fr) var(--pagegrid-gutter))
        minmax(0, 100fr)
        var(--pagegrid-coldef-gutteroutside)
        var(--pagegrid-coldef-sidemargin);
  }
  .page--withsidebar .widget-accordion__item__header, .page--withsidebar .widget-accordion__item__body,
  .page--withsidebar .widget-accordion__item:last-child::after {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
  }
}
/* When there isn't much extra space, just use the full width */
@media (min-width: 1024px) and (max-width: 1300px) {
  .page--withsidebar .pagesidebar {
    grid-column: 19/span 8;
  }
  .page--withsidebar .page__contentarea {
    --pagegrid-col-default: 3 / span 15;
    --pagegrid-col-narrow: 3 / span 15;
    --pagegrid-col-wide: 3 / span 15;
  }
  .page--withsidebar .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    display: none;
  }
}