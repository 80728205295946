@charset "UTF-8";
.ttresource-sidebar__menu {
  background: #642F68;
  color: #FFFFFF;
  padding: 15px 0px 25px 0px;
}

.ttresource-sidebar__menu h2 {
  color: #FFFFFF;
  font: var(--sidebar-heading-font);
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.ttresource-sidebar__menu ul,
.ttresource-sidebar__menu li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ttresource-sidebar__menu a {
  display: flex;
  align-items: baseline;
  color: inherit;
  text-decoration: none;
  font: 15px/20px var(--rtd-text-fontfamily);
  padding: 6px 0;
  padding-left: 15px;
  padding-right: 15px;
}

.ttresource-sidebar__menu a .icon {
  flex: none;
  width: 20px;
}

.ttresource-sidebar__menu a .icon::before {
  content: var(--fa-chevron-right);
  font: var(--fontawesome-regular);
  font-size: 11.5px;
  margin-right: 14px;
  position: relative;
  top: -1px;
}

.ttresource-sidebar__menu a[aria-current] {
  background: rgba(0, 0, 0, 0.5);
}

.ttresource-sidebar__menu a[aria-current] .icon::before {
  position: relative;
  left: -3px;
  --fa-dot-circle: "";
  content: var(--fa-dot-circle);
}

.ttresource-sidebar__menu li a:hover .text {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.pageheader-bottombar__menuwrapper {
  margin-left: auto;
  flex: none;
  position: relative;
}

.pageheader-bottombar__menutoggle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border: 2px solid var(--color-red);
  color: var(--color-purple);
  text-decoration: none;
}

.pageheader-bottombar__menutoggle:hover {
  background: var(--color-red);
  color: #FFFFFF;
}

/*
.pageheader-bottombar__sectionmenu
{
  position: absolute;
  right: 0;

  width: max-content;
  max-width: min(600px, calc(100vw - 50px));
  z-index: 100;
}
*/