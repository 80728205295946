/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
Used in inGenious, JZOJP, TUE

If Internet Explorer 11 wouldn't need to be supported we could:
- use grid + grid-gap + autoplacement (IE doesn't support grid gap, but autoplacement could be fakes using nth-child and setting a column+row or using a polyfill script)
- use flex and CSS vars for columns (can we use them in media queries?)


NOTES:
- We use width instead of flex-basis because IE doesn't support calc() in flex-basis
*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.filteredoverview__feedbackandviewbar {
  margin-top: 22px;
}

.projects__results.projects__results {
  margin-top: 25px;
}

html.page--projectsoverview .page__balloon {
  height: 83px;
}

.header-projectpage--withfoldout,
.page--projectsoverview .page__body,
.page--projectsoverview .page__balloon {
  background-color: var(--color-lightgrey);
}

@media (max-width: 767px) {
  html.page--projectsoverview .projects__filters, html.page--projectsoverview .projects__results {
    display: block;
  }
  html.page--projectsoverview .projects__filters > * > * + *, html.page--projectsoverview .projects__results > * > * + * {
    margin-top: 30px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  html.page--projectsoverview .projects__filters, html.page--projectsoverview .projects__results {
    overflow: hidden;
  }
  html.page--projectsoverview .projects__filters > *, html.page--projectsoverview .projects__results > * {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: -30px;
  }
  html.page--projectsoverview .projects__filters > * > *, html.page--projectsoverview .projects__results > * > * {
    width: calc((100% - 30px * 2) / 2);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
@media (min-width: 1000px) and (max-width: 1279px) {
  html.page--projectsoverview .projects__filters, html.page--projectsoverview .projects__results {
    overflow: hidden;
  }
  html.page--projectsoverview .projects__filters > *, html.page--projectsoverview .projects__results > * {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: -30px;
  }
  html.page--projectsoverview .projects__filters > * > *, html.page--projectsoverview .projects__results > * > * {
    width: calc((100% - 30px * 3) / 3);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
@media (min-width: 1280px) {
  html.page--projectsoverview .projects__filters, html.page--projectsoverview .projects__results {
    overflow: hidden;
  }
  html.page--projectsoverview .projects__filters > *, html.page--projectsoverview .projects__results > * {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: -30px;
  }
  html.page--projectsoverview .projects__filters > * > *, html.page--projectsoverview .projects__results > * > * {
    width: calc((100% - 30px * 4) / 4);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
html.page--projectsoverview .tagcategory {
  background-color: #FFFFFF;
}
html.page--projectsoverview .tagcategory__title {
  font: bold 20px/24px var(--rtd-heading-fontfamily);
  height: 44px;
  line-height: 44px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: var(--color-blue);
  color: #FFFFFF;
  text-transform: uppercase;
}

html.page--folderwithfilters .tagcategory__title {
  font: bold 17px/24px var(--rtd-text-fontfamily);
  height: 44px;
  line-height: 44px;
}

.tagcategory__options {
  padding: 18px 16px 30px 16px;
  color: #000000;
}

.checkboxlist__option {
  display: flex;
  align-items: baseline;
}

.checkboxlist__option > input + label {
  margin-right: 10px;
}

.checkboxlist__option > input + label + label {
  padding-top: 8px;
  padding-bottom: 8px;
}

.spc-toggle + label + label {
  margin-left: 13px;
  font: 600 18px/24px var(--rtd-heading-fontfamily);
  color: #000000;
}

input[type=checkbox].spc-toggle + label::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  font-size: 11px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 5px;
  line-height: 19px;
}

@media (min-width: 1023px) {
  .header-page--projectsoverview,
  html.filteredoverview--showfilter .projects__filters {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-page--projectsoverview__title,
  .projects__results__items {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (max-width: 1023px) {
  .projects__results {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.projects__results {
  margin: -4px;
  padding: 4px;
}

.checkboxlist__option,
.filterbar__option {
  display: flex;
}

.checkboxlist__option input + label {
  flex: none;
  margin-right: 10px;
}

@media (max-width: 500px) {
  .header__filterbar__foldout {
    padding-bottom: 0;
  }
  .projects__filters__label {
    display: none !important;
  }
  .tagcategory {
    padding-top: 22px;
    padding-bottom: 25px;
  }
  .tagcategory + .tagcategory {
    margin-top: 0 !important;
  }
}
@media (min-width: 501px) {
  .header__filterbar__foldout {
    padding-bottom: 30px;
  }
}