/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.widget-ctablocks {
  grid-column: var(--pagegrid-col-fullwidth);
  z-index: 10;
  background-color: var(--color-lightgrey);
  padding-top: 32px;
  padding-bottom: 32px;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-ctablocks__title {
  grid-column: var(--pagegrid-col-verywide);
  margin-bottom: 22px;
}

.widget-ctablocks__items {
  grid-column: var(--pagegrid-col-verywide);
}

.widget-ctablocks__item-top {
  position: relative;
  overflow: hidden;
}

.widget-ctablocks__item-top::before {
  display: block;
  content: "";
  padding-top: 100%;
}

.widget-ctablocks__item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}

.widget-ctablocks__item:hover {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.widget-ctablocks__item:hover .widget-ctablocks__item-image {
  transform: scale(1.1);
}

.widget-ctablocks__item-overlay {
  position: relative;
  z-index: 0;
  background-color: #c72125;
  mix-blend-mode: multiply;
}

.widget-ctablocks__item-title {
  z-index: 1;
  text-decoration: none;
  color: #FFFFFF;
  font: var(--ctablocks-item-title-font);
  display: flex;
  align-items: center;
  position: relative;
}

.widget-ctablocks__item {
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr min-content;
  text-decoration: none;
}

.widget-ctablocks__item-top {
  display: block;
  grid-column: 1/span 2;
  grid-row: 1/span 2;
}

.widget-ctablocks__item-overlay,
.widget-ctablocks__item-title {
  grid-column: 1/span 1;
  grid-row: 2;
}

.widget-ctablocks__item-title {
  padding: 13px 21px;
  align-self: center;
  display: flex;
  align-content: center;
}

.widget-ctablocks__item:hover {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  .widget-ctablocks__item::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 48px;
    background-color: var(--color-red);
    color: #FFFFFF;
    display: block;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: var(--fa-arrow-right);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .widget-ctablocks__item:hover::after {
    background-color: var(--color-theme-darker);
  }
}
@media (min-width: 768px) {
  .widget-ctablocks__item-title {
    min-height: 90px;
  }
  .widget-ctablocks__item::after {
    grid-column: 2;
    grid-row: 2;
    width: 80px;
    align-self: stretch;
    background-color: var(--color-red);
    color: #FFFFFF;
    display: block;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: var(--fa-arrow-right);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 0;
  }
  .widget-ctablocks__item:hover::after {
    background-color: var(--color-theme-darker);
  }
}
@media (max-width: 320px) {
  .widget-ctablocks__item-title {
    --ctablocks-item-title-font: bold 18px/20px var(--rtd-heading-fontfamily);
    padding: 16px 10px 16px 10px;
  }
}
@media (max-width: 767px) {
  .widget-ctablocks {
    --ctablocks-item-title-font: bold 22px/26px var(--rtd-heading-fontfamily);
  }
  .widget-ctablocks__items > * + * {
    margin-top: 30px;
  }
  .widget-ctablocks__item-title {
    padding: 17px 20px 17px 20px;
  }
}
@media (min-width: 768px) {
  .widget-ctablocks__items {
    display: grid;
    gap: 30px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .widget-ctablocks {
    --ctablocks-item-title-font: bold 22px/24px var(--rtd-heading-fontfamily);
  }
  .widget-ctablocks__items {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .widget-ctablocks__item-title {
    padding: 17px 20px 17px 20px;
  }
}
@media (min-width: 1000px) {
  .widget-ctablocks {
    --ctablocks-item-title-font: bold 22px/24px var(--rtd-heading-fontfamily);
  }
  .widget-ctablocks__items {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
  .widget-ctablocks__item-title {
    padding: 17px 20px 17px 20px;
  }
}