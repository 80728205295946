/**
Deeplinks support
first written for TUE webdesign
*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
  grid-column-start: 3;
  grid-column-end: 6;
  /*background-color: #0F0 !important;*/
  /* take deeplinks out of flow */
  position: -webkit-sticky;
  position: sticky;
  top: 95px;
  z-index: 9; /* keep over page content */
}
@media (max-width: 1023px) {
  .deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
    display: none;
  }
}

.deeplinks {
  /* take out of flow so content isn't pushed down.
     (or in case of our grid our row won't be stretched)
  */
  position: absolute;
  margin-bottom: 20px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
  width: 100%;
  max-width: 203px;
}

a.deeplinks__item {
  /*
  NOTES:
  - Use display: table;
    display: table; would shrinkwrap, however display: table; doesn't
    use overflow: hidden; because that would need to be applied to table-cell

  - Shrinkwrap using min-content
    - Container - width: 100%;
    - Items     - display: block; width: min-content; max-width: 100%;
    Doesn't work in IE (no min-content support, except in grid)

  - (NOT tested) use grid?

  - Flex (when using align-items: flex-start) items will be shrinkwrapped.
    - width: 100%; to not allow the flex to just grow to fit flex children
    - align-items: flex-start; to shrinkwrap to the left

  display: table;

  display: block;
  width: min-content;
  */
  flex: none;
  max-width: 100%;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.27);
  padding: 2px 4px;
  background-color: #FFFFFF;
  color: #666666;
  font: 12px/15px var(--rtd-text-fontfamily);
  text-decoration: none;
  padding: 7px 10px;
  pointer-events: auto;
}

.deeplinks__item__label {
  word-break: break-word;
  /*
  -- single line ellipsis
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  */
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

a.deeplinks__item:active,
a.deeplinks__item:hover {
  color: var(--color-blue);
}

.deeplinks__item + .deeplinks__item {
  margin-top: 9px;
}