/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
/*

Views
- Mobile         -> stacked, image above
- Tablet/desktop -> Image floating top right

*/
/* When a CTA widget starts directly below an image header
   we want it to stick to the header with a grey background around it. */
.page-header--withimage + .page__body .page-contentstart + .widget-cta {
  /*
    --header-to-content-margin: 0; // stick to header

    grid-column: var(--pagegrid-col-fullwidth);

    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);

    .widget-cta__background
    {
      grid-column: var(--pagegrid-col-verywide-outsidegutter);
      grid-row: 1;
      background-color: #F5F5F5;
    }

    .widget-cta__content
    {
      grid-row: 1;
      margin-top:    53px;
      margin-bottom: 42px;
    }
  */
}

.widget-cta--dontcapitalize .widget-cta__title {
  text-transform: none;
}

.widget-cta {
  z-index: 10;
}

.widget-cta__content {
  grid-column: var(--pagegrid-col-verywide);
  background: var(--currenttheme-background);
}

.widget-cta__title {
  color: var(--currenttheme-heading-color);
}

.widget-cta__text {
  --rtd-heading-color: var(--currenttheme-heading-color);
  --rtd-text-color: var(--currenttheme-text-color);
  --rtd-link-color: var(--currenttheme-link-color);
}

.widget-cta__text a {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.widget-cta--withbackground .widget-cta__text {
  padding-bottom: 30px;
}

.widget-cta__image {
  background-size: cover;
}

.widget-cta__text > * + * {
  margin-top: 25px;
}

.widget-cta__title {
  margin-top: 0;
}

@media (max-width: 699px) {
  .widget-cta {
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
  .widget-cta__text {
    padding-top: var(--widget-cta-stacked-pad-top);
    padding-bottom: var(--widget-cta-stacked-pad-bottom);
    padding-left: var(--widget-cta-stacked-pad-h);
    padding-right: var(--widget-cta-stacked-pad-h);
  }
}
@media (min-width: 700px) {
  .widget-cta {
    margin-top: 60px;
    grid-column: var(--pagegrid-col-verywide-outsidegutter);
  }
}
@media (min-width: 1024px) {
  .widget-cta {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 700px) {
  .widget-cta--focusleft .widget-cta__imagewrapper {
    float: left;
    width: 50%;
    margin-right: 30px;
    margin-top: -30px;
    margin-bottom: 15px;
  }
  .widget-cta--focusright .widget-cta__imagewrapper {
    float: right;
    width: 50%;
    margin-left: 30px;
    margin-top: -30px;
    margin-bottom: 15px;
  }
  .widget-cta__text {
    padding-top: var(--widget-cta-sidebyside-pad-top);
    padding-bottom: var(--widget-cta-sidebyside-pad-bottom);
    padding-left: var(--widget-cta-sidebyside-pad-h);
    padding-right: var(--widget-cta-sidebyside-pad-h);
  }
}