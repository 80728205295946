/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
- aspect-ratio (all browsers since 2021, iOS 15)
- gap (for grid) - iOS 10.3+
*/
.page--folderwithfilters {
  --grid-spacing: 20px;
  --block-pad-h: 20px;
}
.page--folderwithfilters .page__contentarea.page__contentarea {
  margin-top: 0;
  padding-top: 0;
}
.page--folderwithfilters .filteredoverview__feedbackandviewbar {
  margin-top: 20px;
  margin-bottom: 15px;
}
.page--folderwithfilters .deeplinks-wrapper {
  display: none;
}
.page--folderwithfilters .pagetabs.pagetabs--tabs {
  grid-column: var(--pagegrid-col-verywide);
  /* tab component handles grid within */
}
.page--folderwithfilters .pagetabs.pagetabs--tabs .pagetabs__content {
  margin-bottom: -2px;
}

.page__contentarea > .rtdcontent + .folderwithfilters__items {
  margin-top: 15px;
}

@media (max-width: 767px) {
  .page--folderwithfilters .page__contentarea {
    margin-top: 0;
  }
  .page--folderwithfilters .filteredoverview__feedbackandviewbar {
    margin-top: 14px;
    margin-bottom: 10px;
  }
}
.projectblock-image--empty {
  background-color: #CCCCCC;
}