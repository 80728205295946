/*  .spc-tiles--strip*/
/*, .folderwithfilters__items--listview*/
html
{
  /* /shared/components/spc-multiviewresults
     Support for switching to list or gridview using the same DOM/HTML.

  TUE BOOST
  */
  --component-spctile-padding-h:            30px;
  --component-spctile-padding-v:            20px;
  --component-spctile-radius:               0; /* ? */
  --component-spctile-hover-background:     #F5F5F5;

  --component-spctile-image-listview-width: 200px;
  --component-spctile-image-border:         none;
  --component-spctile-image-border-radius:  0;

  --component-spctile-title-color:          $color-red;
  --component-spctile-title-font:           bold 16px/22px $font-heading;
  --component-spctile-title-text-transform: uppercase;

  --component-spctile-when-color:           #000000;
  --component-spctile-when-font:            600 16px $font-heading;

  --component-spctile-text-color:           #666666;
  --component-spctile-text-font:            15px/24px $font-text;


/*
  --component-spctile-spacing:               0px;

  --component-spctile-background:           transparent;
  --component-spctile-box-shadow:           2px 2px 6px 0 rgba(0,0,0,0.16);

  --component-spctile-padding-h:            14px;
  --component-spctile-padding-v:            14px;
  --component-spctile-radius:               var(--contentpanels-radius-small);

  --component-spctile-hover-background:     var(--color-purple3);
  --component-spctile-hover-title-color:    var(--rtd-heading-color);

  --component-spctile-image-listview-width: 255px;
/ *--component-spctile-image-listview-width: clamp(120px, 10vw, 255px);* /
  --component-spctile-image-border:         1px solid var(--color-purple3);
  --component-spctile-image-border-radius:  var(--contentpanels-radius-small);

  --component-spctile-title-color:          var(--rtd-heading-color);
  --component-spctile-title-font:           18px/24px var(--rtd-heading-fontfamily);
  --component-spctile-title-text-transform: none;

  --component-spctile-when-color:           #767676;
  --component-spctile-when-font:            17px/24px var(--rtd-heading-fontfamily);

  --component-spctile-text-color:           #333333;
/ *  --component-spctile-text-font:            17px/24px var(--rtd-text-fontfamily);* /
  --component-spctile-text-font:            var(--rtd-text-subtle-font);
*/
}

.widget-contentsummary
{
  --component-spctile-padding-h:            30px;
  --component-spctile-padding-v:            20px !important;

}
