/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.projectdetails__meta h2 {
  color: #000000;
  font: var(--rtd-h3-font);
  margin-bottom: 8px;
}

.projectdetails__meta__table:empty {
  display: none;
}

.projectdetails__meta__table th {
  vertical-align: baseline;
  padding: 0;
  text-align: left;
  padding-right: 20px;
  color: #000000;
  font: var(--rtd-h3-font);
}

.projectdetails__meta__table td {
  vertical-align: baseline;
  padding: 0;
  width: 100%; /* use remaining space so the left column won't take it */
  font-size: 15px;
}

.spc-tagslist {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}