* {
  box-sizing: border-box;
}

html, body {
  padding: 0;
  margin: 0;
}

.page-header + .page__contentarea .page-contentstart + .widget-ctablocks,
.page-header + .page__contentarea .page-contentstart + .widget-news,
.page-header + .page__contentarea .page-contentstart + .widget-persons,
.page-header + .page__contentarea .page-contentstart + .widget-projects,
.page-header + .page__contentarea .page-contentstart + .widget-richtext:not(.widget--background-white) {
  margin-top: 0;
}

.page__contentarea .widget-ctablocks:last-child,
.page__contentarea .widget-news:last-child,
.page__contentarea .widget-persons:last-child,
.page__contentarea .widget-projects:last-child,
.page__contentarea .widget-richtext:not(.widget--background-white):last-child {
  margin-bottom: 0;
}
.page__contentarea .widget-ctablocks + .page__balloon,
.page__contentarea .widget-news + .page__balloon,
.page__contentarea .widget-persons + .page__balloon,
.page__contentarea .widget-projects + .page__balloon,
.page__contentarea .widget-richtext:not(.widget--background-white) + .page__balloon {
  margin-bottom: 0;
}

/* Have some widgets which have their own background
   stick to the footer by collapsing the (height of) the .page__balloon element.
   (otherwise we get a small white space between two coloured blocks which looks ugly)
*/
.widget-ctablocks + .page__balloon, .widget-news + .page__balloon, .widget-persons + .page__balloon, .widget-projects + .page__balloon, .widget-richtext:not(.widget--background-white) + .page__balloon {
  --whitespace-footer-content: 0;
}

.visually-hidden {
  position: absolute;
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}