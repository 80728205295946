/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.header-filterbar__query {
  /* clears the 'X' from Chrome */
}
.header-filterbar__query::-webkit-search-decoration, .header-filterbar__query::-webkit-search-cancel-button, .header-filterbar__query::-webkit-search-results-button, .header-filterbar__query::-webkit-search-results-decoration {
  display: none;
}

.header-projectpage {
  grid-column: var(--pagegrid-col-fullwidth);
  grid-row: 3;
}

@media (max-width: 649px) {
  .header-projectpage {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 15px;
    padding-bottom: 9px;
    background-color: var(--color-blue);
  }
  .header-projectpage__background {
    display: none;
  }
  .header-projectpage__title {
    font: bold 26px/30px var(--rtd-heading-fontfamily);
    color: #FFFFFF;
  }
  .header-projectpage__description {
    margin-top: 9px;
  }
  .header-projectpage__description.header-projectpage__description > * {
    color: #FFFFFF;
    font: 12px/18px var(--rtd-text-fontfamily);
  }
  .header-filterbar {
    margin-top: 25px;
  }
  .header-filterbar__querycontainer {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .header-filterbar__query {
    flex: 1 0 0px;
    height: 44px;
    border: 0;
    background-color: #FFFFFF;
    font: 15px var(--rtd-heading-fontfamily);
    color: #6F6F6F;
  }
  .header-filterbar__submit {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    position: absolute;
    right: 15px;
    transform: scaleX(-1);
    height: 100%;
    font-size: 0;
  }
  .header-filterbar__submit .fa-search {
    font-size: 18px;
    color: var(--color-blue);
  }
  .header-filterbar__togglefilters {
    margin-top: 8px;
    border: 0 none;
    outline: none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font-family: var(--rtd-text-fontfamily);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 50px;
    padding: 0 20px;
    border-radius: 0;
    transition: background-color 0.3s;
    cursor: pointer;
    background-color: var(--color-blue);
    color: #FFFFFF;
    border: 0;
    width: 100%;
    height: 44px;
    text-transform: none;
  }
  .header-filterbar__togglefilters:hover {
    background-color: var(--color-blue-hover);
    color: #FFFFFF;
  }
  .header-filterbar__togglefilters .wh-form__button--disabled {
    background-color: #AAAAAA;
    color: #666666;
    cursor: default;
  }
  .header-filterbar__togglefilters:hover {
    text-decoration: none;
  }
  .header-filterbar__togglefilters__label {
    margin-left: auto;
    margin-right: auto;
  }
  .header-filterbar__togglefilters__icon {
    font-size: 18px;
  }
  .projects__filters {
    background-color: var(--color-lightgrey);
  }
}
@media (min-width: 650px) {
  .header-projectpage--withfoldout,
  .projects__filters {
    background-color: var(--color-lightgrey);
  }
  .header-projectpage {
    background-image: none !important;
    position: -webkit-sticky;
    position: sticky;
    top: -126px;
    z-index: 1;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content 1fr min-content 20px;
  }
  .header-projectpage__background {
    height: 300px;
    grid-column: var(--pagegrid-col-fullwidth);
    grid-row: 1/-2;
    background-color: var(--color-blue);
  }
  .header-projectpage__title {
    grid-row: 1;
    margin-top: 20px;
    grid-column: var(--pagegrid-col-verywide);
    font: 600 50px var(--rtd-heading-fontfamily);
    color: #FFFFFF;
  }
  .header-projectpage__description {
    grid-row: 2;
    grid-column: var(--pagegrid-col-verywide);
    max-width: 844px;
    margin-top: 16px;
  }
  .header-projectpage__description.header-projectpage__description > * {
    color: #FFFFFF;
    font: 15px/24px var(--rtd-text-fontfamily);
  }
  .header-filterbar {
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 3/span 2;
    /*
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    */
    display: flex;
    background-color: #FFFFFF;
    box-shadow: 0 2px 13px 4px rgba(0, 0, 0, 0.06);
    padding: 20px 20px;
  }
  .header-filterbar__querycontainer {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .header-filterbar__querycontainer .wh-autocomplete-container {
    width: 100%;
  }
  .header-filterbar__query {
    flex: 1 0 0px;
    height: 44px;
    border: 1px solid #9E9E9E;
    background-color: #FFFFFF;
    font: 15px var(--rtd-text-fontfamily);
    color: #6F6F6F;
  }
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    border: 0 none;
    outline: none;
    -webkit-appearance: none;
    text-decoration: none;
    box-shadow: none;
    font-family: var(--rtd-text-fontfamily);
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    height: 50px;
    padding: 0 20px;
    border-radius: 0;
    transition: background-color 0.3s;
    cursor: pointer;
    background-color: var(--color-blue);
    color: #FFFFFF;
    border: 0;
    height: 44px;
    text-transform: none;
  }
  .header-filterbar__submit:hover,
  .header-filterbar__togglefilters:hover {
    background-color: var(--color-blue-hover);
    color: #FFFFFF;
  }
  .header-filterbar__submit .wh-form__button--disabled,
  .header-filterbar__togglefilters .wh-form__button--disabled {
    background-color: #AAAAAA;
    color: #666666;
    cursor: default;
  }
  .header-filterbar__submit:hover,
  .header-filterbar__togglefilters:hover {
    text-decoration: none;
  }
  .header-filterbar__submit {
    padding-left: 16px;
    padding-right: 16px;
    font: 15px var(--rtd-text-fontfamily);
    cursor: pointer;
  }
  .header-filterbar__submit .fa-search {
    margin-left: 52px;
  }
  .header-filterbar__togglefilters {
    margin-left: 30px;
  }
  .header-filterbar__togglefilters__icon {
    margin-left: 30px;
  }
}
@media (min-width: 650px) and (max-width: 767px) {
  .header-filterbar__query {
    padding-right: 0 !important;
  }
  .header-filterbar__submit .fa-search,
  .header-filterbar__togglefilters__icon {
    margin-left: 15px !important;
  }
}
.header-filterbar__foldout {
  display: none;
  color: #000000;
}

html.filteredoverview-showfilters .header-filterbar__foldout {
  display: block;
}

html.filteredoverview-showfilters .page__body,
html.filteredoverview-showfilters .footer {
  display: none;
}

html.filteredoverview-showfilters body {
  padding-bottom: 64px;
}