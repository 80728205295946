@charset "UTF-8";
.page--folderwithfilters--grid .page__contentarea > .folderwithfilters__rtdcontent {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
.page--folderwithfilters--grid .folderwithfilters__rtdcontent > * {
  grid-column: var(--pagegrid-col-verywide);
}

.folderwithfilters__items--gridview {
  grid-column: var(--pagegrid-col-verywide);
  margin-left: -4px;
  margin-right: -4px;
  padding: 4px;
}

.folderwithfilters__items--gridview {
  grid-gap: var(--grid-spacing);
  gap: var(--grid-spacing);
}
@media (max-width: 320px) {
  .folderwithfilters__items--gridview .projectblock-meta {
    padding: 7px 30px 7px var(--block-pad-h);
  }
  .folderwithfilters__items--gridview .projectblock-title {
    font: bold 18px/20px var(--rtd-heading-fontfamily);
    margin-top: 3px;
  }
}
@media (max-width: 767px) {
  .folderwithfilters__items--gridview .projectblock-title {
    font: bold 19px var(--rtd-heading-fontfamily);
    margin-top: 3px;
  }
}
@media (max-width: 767px) {
  .folderwithfilters__items--gridview .projectblock-meta {
    padding: 7px 30px 7px var(--block-pad-h);
  }
  .folderwithfilters__items--gridview .projectblock-title {
    margin-top: 3px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .folderwithfilters__items--gridview .projectblock-meta {
    padding: 11px 30px 7px var(--block-pad-h);
  }
  .folderwithfilters__items--gridview .projectblock-title {
    font: bold 20px/24px var(--rtd-heading-fontfamily);
    margin-top: 3px;
  }
}
@media (min-width: 1000px) {
  .folderwithfilters__items--gridview .projectblock-meta {
    padding: 19px 30px 12px var(--block-pad-h);
  }
  .folderwithfilters__items--gridview .projectblock-title {
    font: bold 24px/29px var(--rtd-heading-fontfamily);
    margin-top: 3px;
  }
}
@media (max-width: 767px) {
  .folderwithfilters__items--gridview {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .folderwithfilters__items--gridview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .folderwithfilters__items--gridview .projectblock-title::before {
    /*
    \00a0 non breakable space
    \200b zero width space
    \A newline
    */
    float: left;
    content: "​\a​";
    white-space: pre;
    vertical-align: top;
  }
}
@media (min-width: 1000px) {
  .folderwithfilters__items--gridview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    /* Until we can use subgrid's we'll try to get a more consistant
       view by forcing the title to be two lines on each block. */
  }
  .folderwithfilters__items--gridview .projectblock-title::before {
    /*
    \00a0 non breakable space
    \200b zero width space
    \A newline
    */
    float: left;
    content: "​\a​";
    white-space: pre;
    vertical-align: top;
  }
}
@media (min-width: 1200px) {
  .folderwithfilters__items--gridview[data-gridmaxcols="4"] {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    /* Until we can use subgrid's we'll try to get a more consistant
       view by forcing the title to be two lines on each block. */
  }
  .folderwithfilters__items--gridview[data-gridmaxcols="4"] .projectblock-meta {
    --block-pad-h: 15px;
    padding: 19px var(--block-pad-h) 12px var(--block-pad-h);
  }
  .folderwithfilters__items--gridview[data-gridmaxcols="4"] .projectblock-title {
    font: bold 20px/26px var(--rtd-heading-fontfamily);
  }
  .folderwithfilters__items--gridview[data-gridmaxcols="4"] .projectblock-title::before {
    /*
    \00a0 non breakable space
    \200b zero width space
    \A newline
    */
    float: left;
    content: "​\a​";
    white-space: pre;
    vertical-align: top;
  }
}
.folderwithfilters__items--gridview .projectblock {
  display: grid;
  grid-template-columns: 100%;
  position: relative;
  text-decoration: none;
  background-color: #FFFFFF;
  border: 1px solid #DCDCDC;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 0;
}
.folderwithfilters__items--gridview .projectblock:focus {
  outline: 3px solid var(--color-blue);
  outline-offset: 2px; /* have some offset because the outline might not have a good contrast with all blocks */
}
.folderwithfilters__items--gridview .projectblock.notmatching {
  display: none !important;
}
.folderwithfilters__items--gridview a.projectblock.projectblock {
  text-decoration: none;
  color: #FFFFFF;
}
.folderwithfilters__items--gridview .projectblock:hover {
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.folderwithfilters__items--gridview .projectblock:hover .projectblock-image {
  transform: scale(1.05);
}
.folderwithfilters__items--gridview .projectblock-top {
  grid-column: 1;
  grid-row: 1;
  flex: none;
  position: relative;
  overflow: hidden;
  aspect-ratio: var(--gridimage-aspectratio);
}
.folderwithfilters__items--gridview .projectblock-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: var(--gridimage-aspectratio);
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}
.folderwithfilters__items--gridview .projectblock-meta {
  grid-column: 1;
  grid-row: 1;
  align-self: end;
  word-break: break-word; /* text must allways fix */
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(0.5px) contrast(60%);
  z-index: 2;
}
.folderwithfilters__items--gridview .projectblock-when {
  font-size: 12px;
}
.folderwithfilters__items--gridview .projectblock-title {
  color: var(--color-theme);
}
.folderwithfilters__items--gridview .projectblock::after {
  content: "";
  grid-column: 1;
  grid-row: 1;
  background: radial-gradient(circle, rgba(0, 103, 180, 0) 25%, rgba(0, 103, 180, 0.3) 100%), radial-gradient(circle, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.6) 100%);
  outline: 3px solid var(--color-blue);
  outline-offset: 2px; /* have some offset because the outline might not have a good contrast with all blocks */
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;
}
.folderwithfilters__items--gridview .projectblock:hover::after {
  content: "";
  opacity: 1;
}
.folderwithfilters__items--gridview .projectblock:hover .projectblock-title {
  color: var(--color-theme-hover);
}
.folderwithfilters__items--gridview .widget-ctablocks__description {
  padding: 15px;
  font-family: var(--rtd-text-fontfamily);
  font-size: 14px;
  line-height: 20px;
  color: var(--rtd-text-color);
}
.folderwithfilters__items--gridview.folderwithfilters__items--withimages .projectblock-title {
  color: #FFFFFF;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.45);
}
.folderwithfilters__items--gridview .projectblock-description {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  align-self: baseline;
  justify-content: space-between;
  width: 100%;
  flex: 1 0 auto;
  padding: 17px 20px 14px var(--block-pad-h);
  color: var(--rtd-text-color);
  font: 14px/20px var(--rtd-text-fontfamily);
}
.folderwithfilters__items--gridview .projectblock-description::after {
  margin-top: 2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 17px;
  color: #3F3F3F;
}
.folderwithfilters__items--gridview .projectblock-description__text {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}