/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.header-top {
  position: relative;
  background-color: #FFFFFF;
}

.header-top__content {
  display: flex;
  align-items: center;
  height: 90px;
}

@media (min-width: 1024px) {
  .header-top,
  .header-menubar {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .header-top__content,
  .header-menubar__content {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (max-width: 599px) {
  .header-top {
    grid-column: var(--pagegrid-col-fullwidth);
    padding-right: var(--pagegrid-sidepadding);
  }
}
#showsidemainmenu {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute !important; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

#showsidemainmenu:focus ~ .header-top .header-top__showsidemainmenu {
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}

.header-top__showsidemainmenu {
  display: block;
  width: 87px;
  align-self: stretch;
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.header-top__showsidemainmenu svg {
  width: 40px;
  height: 40px;
}

.header-top__logolink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header-top__logo {
  width: 70px;
  height: 30px;
  color: var(--color-red);
  margin-left: 15px;
}

.header-top__logotext {
  color: var(--color-red);
  font-size: 8px;
  line-height: 8.8px;
  font-family: var(--rtd-text-fontfamily);
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 6px;
}

.header-top__search {
  margin-left: auto;
  font-size: 23px;
  transform: scaleX(-1);
  color: #000000;
}

.header-menubar {
  font-family: var(--rtd-text-fontfamily);
  background-color: var(--color-theme);
  color: #FFFFFF;
  position: relative;
}

.header-menubar__content {
  display: flex;
  align-items: stretch;
  font-size: 15px;
  line-height: 18px;
}

.sitetitle {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  margin-right: 20px;
}

a.sitetitle {
  text-decoration: none;
  color: inherit;
}

.header-menubar__seperator {
  display: inline-block;
  margin-left: 18px;
  margin-right: 18px;
}

.header-menubar__seperator:before {
  font-size: 35px;
}

.header-menubar__currentitem {
  font-weight: 500;
}