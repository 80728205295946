@font-face
{
  font-family: Lato;
  src: local("Lato Regular"), local("Lato-Regular")
     , url(lato-regular.woff2) format("woff2")
     , url(lato-regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap
}

@font-face
{
  font-family: Lato;
  src: local("Lato Bold"), local("Lato-Bold")
     , url(lato-bold.woff2) format("woff2")
     , url(lato-bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}

@font-face
{
  font-family: Gilroy;
  src: url(Gilroy-Light.woff2) format("woff2")
     , url(Gilroy-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face
{
  font-family: Gilroy;
  src: url(Gilroy-ExtraBold.woff2) format("woff2")
     , url(Gilroy-ExtraBold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap
}
