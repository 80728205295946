@charset "UTF-8";
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*

Styling suggestions:

- Use a form, so virtual keyboards get a "Go"/"Submit" button
- Use aria-label's
- Wrap the input in a flex container and a submit button next to it.
  This way depending on layout we can style the button to be next to it
  and position it absolute to use it as search icon/button within the input.
- Wrap the input in a flex container + add data-suggestparent="parent" so
  the suggestions open directly under the search input+submit button at
  the correct position and full width.
- Tabindex for filters toggle button

    <form class="header-filterbar"
         >
      <div class="header-filterbar__querycontainer">
        <input class="header-filterbar__query"
               name="query"
               data-suggest="tue:projects"
               data-suggestparent="parent"
               placeholder="Search for project by name or keyword…"
               aria-label="Search"
               type="search"
               />
        <button class="header-filterbar__submit"
                aria-label="Search"
                type="submit"
                >
          Search
          <span class="fas fa-search"></span>
        </button>
      </div>

      <div class="header-filterbar__togglefilters
                  filteredoverview__toggleaction
                 "
           tabindex="0"
           >
        <span class="header-filterbar__togglefilters__label">Filter projects</span>
        <span class="header-filterbar__togglefilters__icon far fa-sliders-h"></span>
      </div>
    </form>

*/
.wh-autocomplete-container {
  position: relative;
  width: 100%;
}

.wh-autocomplete-values {
  position: absolute;
  width: 100%;
  border: 1px solid #AAAAAA;
  border-top: 0;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF;
  color: #000;
  margin: 0;
  padding-top: 13px;
  padding-bottom: 9px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

.wh-autocomplete-values:empty::before {
  content: "No matches";
  padding-left: 15px;
}

.wh-autocomplete-values li {
  list-style: none;
}

.wh-autocomplete-values li.suggestion {
  margin: 0;
  padding: 4px 15px;
  cursor: pointer;
}

.wh-autocomplete-values li.suggestion:hover {
  background-color: #EEEEEE;
}

.wh-autocomplete-values li.suggestion:focus {
  outline: 0;
  background-color: #EEEEEE;
}

.wh-autocomplete-values li.suggestion .match {
  font-weight: bold;
}