/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
html {
  --pageheader-background-col: var(--pagegrid-col-verywide-outsidegutter-bg);
}
@media (max-width: 1450px) {
  html {
    --pageheader-background-col: var(--pagegrid-col-fullwidth);
  }
}

.page-header--withimage {
  --pageheader-whitespace-above-textpanel: 3fr;
  --pageheader-whitespace-below-textpanel: 1fr;
  /* When we have a searchbar we want to shrink
     the upper part (with image) of the page-header
     so the page header doesn't get huge. */
}
.page-header--withimage.pageheader--size-compact {
  --pageheader-whitespace-below-textpanel: 3fr;
}
@media (max-width: 599px) {
  .page-header--withimage {
    --pageheader-whitespace-below-textpanel: 0 !important;
  }
}

.pageheader__textcontent {
  display: contents;
}

.page-header {
  position: relative;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__background,
.page-stickycontent__background {
  background-size: cover !important;
}

.page-header__navpath {
  height: 55px;
  border-bottom: 1px solid #D3D3D3;
}

.page-header__date {
  font: 14px var(--rtd-text-fontfamily);
  color: #767676;
  text-transform: uppercase;
}

.pageheader__description {
  max-width: 790px;
  text-wrap: balance;
  text-wrap: pretty;
  --rtd-text-font: var(--rtd-pageheader-text-font);
}

.pageheader--textual .pageheader__description {
  margin-top: 10px;
}

.page-header__tags .tueprojecttag {
  margin-left: 5px;
}

/* Meta and location bar */
.page-header__metastuff {
  font: bold 19px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  /* prevent the antialiasing making the font even more bold
     (unfortunately the Gilroy font only has a Light and ExtraBold version */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.page-header__metastuff > * + *::before {
  content: " | ";
}

@media (max-width: 799px) {
  .page-header__navpath.page-header__navpath,
  .page__body__stickycontent {
    display: none !important;
  }
}
/* for landscape mobile viewing we don't want the extra
   navigation path sticking over the screen because
   we don't have much space in the height. */
@media (max-height: 500px) {
  .page__body__stickycontent {
    display: none !important;
  }
}
@media (min-width: 800px) {
  .page-header__mobilebacklink {
    display: none;
  }
}
.page-header__navpath__backlink {
  margin-left: auto;
  color: var(--color-red);
  font: bold 14px/17px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  text-decoration: none;
}

.page-header__navpath__backlink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-arrow-right);
  font-size: 13px;
  margin-right: 12px;
}

.page-header__mobilebacklink {
  position: absolute;
  left: 13px;
  top: 17px;
  color: #FFFFFF;
  font: bold 12px var(--rtd-text-fontfamily);
  text-transform: uppercase;
  text-decoration: none;
}

.page-header__mobilebacklink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-angle-left);
  font-size: 11px;
  margin-right: 6px;
}

.page-header--basic {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.page-header--basic .page-header__navpath {
  color: #6F6F6F;
}
.page-header--basic .page-header__navpath {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1;
}
.page-header--basic .page-header__meta {
  margin-top: 35px;
  color: #000000;
}
.page-header--basic .page-header__tags {
  margin-top: 15px;
}
@media (max-width: 499px) {
  .page-header--basic .pageheader__subtitle {
    font: bold 16px/19px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
  }
}
@media (min-width: 500px) {
  .page-header--basic .pageheader__subtitle {
    font: bold 19px/23px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
  }
}
.page-header--basic .page-header__meta, .page-header--basic .page-header__tags {
  grid-column: var(--pagegrid-col-default);
}

.page-header--hasbgimage .page-header__navpath {
  border-bottom: 0;
}

.page-header--withimage {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}
.page-header--withimage .page-header__tags {
  grid-column: var(--pagegrid-col-default);
  margin-top: 15px;
}

.page-header--withimage,
.page-header--withslideshow {
  --rtd-heading-color: #FFFFFF;
}
.page-header--withimage::before,
.page-header--withslideshow::before {
  content: "";
  grid-row-start: 1;
  grid-row-end: -1;
  grid-column-start: 1;
}
.page-header--withimage .page-header__background,
.page-header--withslideshow .page-header__background {
  grid-column: var(--pageheader-background-col);
  grid-row-start: 1;
  grid-row-end: -1;
  background-color: #888;
  background: linear-gradient(180deg, #FBFBFB 0%, #E9E9E9 100%);
}
.page-header--withimage .page-stickycontent__background,
.page-header--withslideshow .page-stickycontent__background {
  grid-column: var(--pageheader-background-col);
  grid-row: 1/-1;
  background-color: #888;
  background: linear-gradient(180deg, #FBFBFB 0%, #E9E9E9 100%);
}
.page-header--withimage .page-header__navpath,
.page-header--withslideshow .page-header__navpath {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1;
}

.page-header--sidebyside {
  --pageheader-padding-top: 32px;
  --pageheader-padding-bottom: 33px;
  --pageheader-background-col: var(--pagegrid-col-fullwidth);
  --pageheader-text-font: 15px/24px var(--rtd-text-fontfamily); /* 16px/18px in default header */
  grid-template-rows: min-content var(--pageheader-padding-top) 1fr var(--pageheader-padding-bottom); /* whitespace */
  /*
    &::before
    {
      content: "";//Stretch to preffered height (FIXED size or 50vh, whichever is smaller)";

      // height: 450px;
      //padding-top: 25%;
      height:     35vw;
      max-height: 35vmin;
      min-height: 320px; // mobile header height
    }
  */
}
.page-header--sidebyside:not(.pageheader--showtextcontent) {
  --pageheader-padding-top: 0;
  --pageheader-padding-bottom: 0;
}
.page-header--sidebyside .page-header--sidebyside.page-header--withimage .pageheader__subtitle {
  font: 600 18px/23px var(--rtd-heading-fontfamily);
}
.page-header--sidebyside .page-header__background {
  grid-row: 1/span 4;
}
.page-header--sidebyside .page-header__background::before {
  grid-row: 1/span 4;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%), rgba(143, 63, 152, 0.92);
}
.page-header--sidebyside .page-header__meta {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 3;
  display: flex;
  flex-wrap: wrap;
  column-gap: 120px;
  row-gap: 30px;
}
.page-header--sidebyside .pageheader__textcontent {
  display: block;
  flex: 4 1 0;
  min-width: 300px;
  max-width: 65vw;
}
.page-header--sidebyside .pageheader__description {
  flex: 7 1 0;
  min-width: 300px;
  max-width: 75vw;
  --rtd-text-color: #FFFFFF;
  --rtd-text-font: var(--pageheader-text-font);
}
@media (max-width: 600px) {
  .page-header--sidebyside {
    --pageheader-padding-top: 28px;
    --pageheader-padding-bottom: 21px;
  }
  .page-header--sidebyside .pageheader__subtitle {
    font: bold 16px/17px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
  }
  .page-header--sidebyside .pageheader__title {
    font: bold 32px/35px var(--rtd-heading-fontfamily);
  }
  .page-header--sidebyside .pageheader__description {
    display: none;
  }
}

.page-header--overlaybar.page-header--withimage {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: auto var(--pageheader-whitespace-above-textpanel) auto auto var(--pageheader-whitespace-below-textpanel);
}
.page-header--overlaybar.page-header--withimage::before {
  content: "";
  height: 35vw;
  max-height: 35vmin;
  min-height: 320px;
}
.page-header--overlaybar.page-header--withimage .page-header__meta::before,
.page-header--overlaybar.page-header--withimage .page-header__meta::after {
  content: "";
  flex: 1 1 5vw;
  max-height: 65px;
}
.page-header--overlaybar.page-header--withimage.pageheader--size-compact::before {
  min-height: 292px;
  max-height: 292px;
}
.page-header--overlaybar.page-header--withimage.pageheader--size-compact .page-header__meta::before, .page-header--overlaybar.page-header--withimage.pageheader--size-compact .page-header__meta::after {
  flex: 1 1 3.5vw;
  max-height: 25px;
}
.page-header--overlaybar.page-header--withimage.pageheader--size-compact .page-header__meta::before {
  max-height: 17px;
}
.page-header--overlaybar.page-header--withimage .page-header__background::before {
  display: block;
  width: 100%;
  height: 100px;
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0) 100%);
}
.page-header--overlaybar.page-header--withimage .page-header__metabar {
  grid-row: 3/span 2; /* background behind "text content" and "searchbar" area */
  grid-column: var(--pageheader-background-col);
  background-color: #c72125;
  mix-blend-mode: multiply;
}
.page-header--overlaybar.page-header--withimage .page-header__meta {
  display: flex;
  flex-direction: column;
  position: relative;
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 3;
}
.page-header--overlaybar.page-header--withimage .pageheader__description {
  margin-top: 10px;
  --rtd-text-color: #FFFFFF;
}

.page-header--withimage .page-header__navpath {
  color: #FFFFFF;
}
.page-header--withimage .page-header__meta {
  color: #FFFFFF;
}
.page-header--withimage .pageheader__subtitle {
  font: bold 19px/23px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}
.page-header--withimage .pageheader__title {
  margin-top: 5px;
  font: bold 50px/62px var(--rtd-heading-fontfamily);
  font-size: max(22px, min(6.5vw, 50px));
  font-size: clamp(22px, 6.5vw, 50px);
  line-height: 100%;
  /* prevent the antialiasing making the font even more bold
     (unfortunately the Gilroy font only has a Light and ExtraBold version */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 599px) {
  .page-header--withimage .pageheader__subtitle {
    font: bold 16px/19px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
  }
  .page-header--withimage .pageheader__title {
    font: bold 26px/30px var(--rtd-heading-fontfamily);
  }
}

@media (max-width: 768px) {
  .pageheader__title {
    font: bold 22px/30px var(--rtd-heading-fontfamily);
  }
}
/*
- we use margin-left on tags to create a minimum distance
- we use a negative margin-left on the container to eat up the space
- justify-content: space-between; distrubutes left over horizontal space
*/
.page-header__tags {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -11px;
  margin-left: -15px;
}

/*
.page-header__tags .balloon
{
  flex: 1 1 10px;
  max-width: 15px;
  min-width: 10px;
}
*/
.page-header__tags .tueprojecttag {
  margin-bottom: 11px;
  margin-left: 15px;
}

.page-header__slideshow {
  grid-column: 3/span 5;
  grid-row: 3/span 2;
  background-color: #8AA;
  display: grid;
  grid-template-columns: 100%;
}

.page-header__slideshow::after {
  grid-column: 1;
  grid-row: 1;
}

.page-header__slideshow::after {
  content: "";
  padding-top: 56.36%;
  display: block;
  width: 100%;
}

.page-header__slideshow .carrousel__cell {
  grid-column: 1;
  grid-row: 1;
  background-size: cover;
}

.page-header__slideshow .carrousel__cell {
  opacity: 1;
  transition: opacity 1s;
}
.page-header__slideshow .carrousel__cell + .page-header__slideshow .carrousel__cell {
  display: none;
}
.page-header__slideshow .carrousel__cell.slideshow--slidein {
  opacity: 0;
}
.page-header__slideshow .carrousel__cell.slideshow--slideout {
  opacity: 0;
}

.page-header--withslideshow {
  --pagegrid-col-verywide: 3 / 18;
}
.page-header--withslideshow .page-header__slideshow-jumpbuttons {
  display: flex;
}
.page-header--withslideshow .page-header__navpath {
  color: #000000;
}
.page-header--withslideshow .pageheader__subtitle, .page-header--withslideshow .pageheader__title {
  color: #000000;
  text-transform: uppercase;
}
.page-header--withslideshow.page-header--withimage .page-header__navpath {
  color: #FFFFFF;
}
.page-header--withslideshow.page-header--withimage .pageheader__subtitle, .page-header--withslideshow.page-header--withimage .pageheader__title {
  color: #FFFFFF;
}
.page-header--withslideshow .page-header__metabar {
  display: none;
}
.page-header--withslideshow .page-header__slideshow-jumpbutton {
  flex: none;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.page-header--withslideshow .page-header__slideshow-jumpbutton {
  background-color: #C7C7C7;
}
.page-header--withslideshow .page-header__slideshow-jumpbutton.slideshow--activedot {
  background-color: #C72125;
}
.page-header--withslideshow .page-header__slideshow-jumpbutton + .page-header__slideshow-jumpbutton {
  margin-left: 14px;
}
@media (max-width: 768px) {
  .page-header--withslideshow {
    display: grid;
  }
  .page-header--withslideshow .page-header__slideshow, .page-header--withslideshow .page-header__slideshow-jumpbuttons {
    grid-column: 1;
    grid-row: 1;
  }
  .page-header--withslideshow .page-header__background {
    display: none;
  }
  .page-header--withslideshow .page-header__slideshow-jumpbuttons {
    align-self: self-end;
    margin-bottom: 11px;
    justify-self: center;
    z-index: 1;
  }
  .page-header--withslideshow .page-header__meta {
    grid-column: 1;
    grid-row: 2;
    background: linear-gradient(180deg, #FBFBFB 0%, #E9E9E9 100%);
    padding: 12px 10px;
  }
  .page-header--withslideshow .page-header__tags {
    grid-column: 1;
    grid-row: 3;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 768px) {
  .page-header--withslideshow {
    display: grid;
    grid-template-columns: calc((100% - 1430px) / 2) 30px minmax(0, 1fr) 30px minmax(0, 1fr) 30px minmax(0, 1.5fr) 30px minmax(0, 0.5fr) 30px repeat(4, minmax(0, 1fr) 30px) calc((100% - 1430px) / 2);
    /*
    Rows:
    1. nav path
    2. whitespace (25px)
    3. slideshow space / Text content
    4. slideshow space (overflow below background)
    5. jumpbuttons and tags
    6. searchbar OR tags
    */
    grid-template-rows: min-content 25px auto 30px min-content min-content min-content; /* searchbar ?? not tested */
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-header--withslideshow {
    grid-template-columns: 0 10px 1fr 30px 1fr 30px 1.5fr 30px 0.5fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr 10px 0;
  }
}
@media (min-width: 768px) {
  .page-header--withslideshow .page-header__background {
    /* end the background 30px above the row with bullets + tags */
    grid-row-end: -5;
  }
  .page-header--withslideshow .page-header__meta, .page-header--withslideshow .page-header__tags {
    grid-column: 9/span 9;
  }
  .page-header--withslideshow .page-header__meta {
    grid-row: 3;
    align-self: center;
  }
  .page-header--withslideshow .pageheader__subtitle {
    font: bold 19px/23px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
  }
  .page-header--withslideshow .pageheader__title {
    font: bold 38px/46px var(--rtd-heading-fontfamily);
  }
  .page-header--withslideshow .page-header__slideshow__sizer::after {
    content: "";
    padding-top: 56.36%;
    display: block;
    width: 100%;
  }
  .page-header--withslideshow .page-header__slideshow__sizer {
    margin-bottom: -30px;
  }
  .page-header--withslideshow .page-header__slideshow-jumpbuttons {
    align-self: center;
    /*
    grid-column: 7;
    grid-row: 6;

    align-self: start;

    display: flex;
    align-items: center;
    height: 28px;
    */
    display: flex;
    grid-column: 9/span 9;
    grid-row: 4;
    align-self: center;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .page-header--withslideshow .page-header__slideshow-jumpbuttons {
    justify-content: flex-end;
  }
}
@media (min-width: 768px) {
  .page-header--withslideshow .page-header__tags {
    grid-row: 6;
  }
}