@charset "UTF-8";
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  max-width: 24px;
  flex: 0 0 24px;
  height: 24px;
  vertical-align: baseline;
  cursor: pointer;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  content: "​";
  transition: box-shadow 5.25s;
}
html[data-whatinput=keyboard] .wh-styledinput input[type=radio]:focus + label,
html[data-whatinput=keyboard] .wh-styledinput input[type=checkbox]:focus + label {
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
  /*
        outline: 2px solid $formcontrol-checkradio-checked-bordercolor;
        outline-offset: 2px;
  */
}
html[data-whatinput=keyboard] .wh-styledinput input[type=radio]:checked:focus + label,
html[data-whatinput=keyboard] .wh-styledinput input[type=checkbox]:checked:focus + label {
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #BFBFBF;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: #979797;
}
.wh-styledinput input[type=radio] + label {
  border: 1px solid #979797;
  border-radius: 50%;
}
.wh-styledinput input[type=radio]:not([disabled]) + label:hover {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.14);
}
.wh-styledinput input[type=radio] + label::after {
  position: relative;
  border-radius: 50%;
  width: 14px;
  height: 14px;
}
.wh-styledinput input[type=radio]:checked + label {
  background-color: #FFF;
  border-color: var(--color-blue);
}
.wh-styledinput input[type=radio]:checked + label::after {
  background-color: var(--color-blue);
}
.wh-styledinput input[type=radio][disabled] + label {
  background-color: #F2F2F2;
  border-color: #BBBBBB;
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #BBBBBB;
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #BFBFBF;
}
.wh-styledinput input[type=checkbox] + label {
  border: 1px solid #979797;
  border-radius: 0;
  color: transparent;
}
.wh-styledinput input[type=checkbox] + label::after {
  content: "\f00c";
}
.wh-styledinput input[type=checkbox]:not([disabled]) + label:hover {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.14);
  color: #D0D0D0;
}
.wh-styledinput input[type=checkbox]:checked + label {
  background-color: var(--color-blue);
  border-color: var(--color-blue);
}
.wh-styledinput input[type=checkbox]:checked + label::after {
  color: #FFFFFF;
}
.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
  border-color: #BBBBBB;
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  color: #BBBBBB;
}
.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox] + label,
.wh-styledinput .wh-form__fieldgroup--error input[type=radio] + label {
  border-color: #cd202c;
}
.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:checked + label::after {
  background-color: #cd202c;
}