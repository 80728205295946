/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

Grid rows:
1 - .header-top
2 - .header-menubar
3 - .header-crumblepath
4 - .pageheader
5 - .page__body
6 - .footer

z-index:

10 - --zindex-overlap-deeplinks: 10;
11 - --zindex-overlap-contentarea: 11; (used by .page__body__stickycontent)

11 - .header-menubar-background
12 - .header-menubar
13 - .header-searchpanel-top/bottom/content/closebutton
14 - .header-menubar:hover ~ .header-menubar-background
15 - .header-menubar:hover

Usage:

- This is an 8 column design setup

- main container will set .spc-contentgrid

- collapseable content should use .spc-contentgrid to establish a grid
  for the subcontent. This way content within can align with the main grid.

- Layout can be overriden using classes or mixins

- !! Content which uses the .spc-contentgrid class will automatically
     lose grid layout when the viewport width goes below $minwidth-usergrid-8col.
     If you need to use another breakpoint, use the mixins instead for more control !!!


It handles:
- footer pushed to bottom of viewport (or page)
- column layout
- ability for deeplinks hovering in contentarea
- topmenu bar scrolling away
- menubar getting sticky
- background for menubar sticky, but scrolling farther to give appearance of menubar shrinking
- menubar disappears when content doesn't fit (flex wrapping into the overflow of the menubar)

*/
/*
These calculations are done in SASS,
because they are used as breakpoints for @media queries
*/
html {
  /* amount of space to keep empty */
  --pagegrid-sidepadding: 0px;
  /* The gutter in which backgrounds and special content can protrude */
  --pagegrid-gutteroutside-normal-min: 10px;
  --pagegrid-gutteroutside-normal-max: 30px;
  --pagegrid-header-protrude-max: 120px;
  --pagegrid-columns: 12;
  --pagegrid-gutter: 30px;
  --pagegrid-gutteroutside: 10px;
  --pagegrid-columnwidth: 65px;
  --pagegrid-maxwidth: 1170px;
  --pagegrid-verywide-maxwidth: 1224px;
  --pagegrid-coldef-sidemargin: max(var(--pagegrid-sidepadding), calc((100% - 1430px) / 2));
  /* Allow gutters to be smaller so we can fit small screen */
}
@media (max-width: 500px) {
  html {
    --pagegrid-gutter: 5px;
  }
}
@media (max-width: 767px) {
  html {
    --pagegrid-coldef-gutteroutside: var(--pagegrid-gutteroutside-normal-min);
  }
}
@media (min-width: 768px) {
  html {
    --pagegrid-coldef-gutteroutside: clamp( var(--pagegrid-gutteroutside-normal-min)
                                          , 2.3vw /* was 6.3vw */
                                          , var(--pagegrid-gutteroutside-normal-max)
                                          );
  }
}

body {
  --pagegrid-col-default-max8col: var(--pagegrid-col-default);
}

/*
 .page__contentarea--rtddoc.page__contentarea__projectdetails-content
{
  // The DU project page uses the full contentarea width
  // (12 column layout)
  --pagegrid-col-default:         3 / span 23;
  --pagegrid-col-default-max8col: 3 / span 17;

  --pagegrid-col-default-left:  var(--pagegrid-col-verywide-left);
  --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
}
*/
.deeplinks-wrapper {
  left: var(--pagegrid-gutteroutside-normal-min);
}

html {
  --pagegrid-template-columns-inside: 0 0 repeat(11, minmax(0, 1fr) 5px) minmax(0, 1fr) 0 0; /* continue grid inside container which is 12col */
  --pagegrid-template-columns: var(--pagegrid-coldef-sidemargin) var(--pagegrid-coldef-gutteroutside) repeat(11, minmax(0, 100fr) var(--pagegrid-gutter)) minmax(0, 100fr) var(--pagegrid-coldef-gutteroutside) var(--pagegrid-coldef-sidemargin);
}

@media print {
  html {
    --pagegrid-template-columns: var(--pagegrid-coldef-sidemargin) minmax(1.5cm, calc((100% - 1110px) / 2)) repeat(11, minmax(0, 1fr) var(--pagegrid-gutter)) minmax(0, 1fr) minmax(1.5cm, calc((100% - 1110px) / 2)) var(--pagegrid-coldef-sidemargin);
  }
}
html {
  --pagegrid-gutter-left-full: 3 / 8; /* no spacing to verywide content */
  --pagegrid-gutter-left: 2 / 6;
  --pagegrid-gutter-right: 23 / 26; /* next to verynarrow */
  --pagegrid-col-verynarrow: 9 / span 11;
  /* ACOI, JZOJP, 4TU ? */
  /*
  --pagegrid-col-narrow:                 7 / span 15;
  --pagegrid-col-narrow-left:            7 / span  7;
  --pagegrid-col-narrow-right:          15 / span  7;
  */
  /* ZVI also takes the gutter around the 8 columns for it's contentarea */
  --pagegrid-col-narrow: 6 / span 17;
  --pagegrid-col-narrow-left: 6 / span 8; /* 2 column / left */
  --pagegrid-col-narrow-right: 15 / span 8;
  --pagegrid-col-wide: 5 / span 19;
  --pagegrid-col-wide-include-gutter: 4 / span 21;
  --pagegrid-col-verywide: 3 / 26;
  --pagegrid-col-verywide-outsidegutter: 2 / 27;
  --pagegrid-col-verywide-outsidegutter-text: var(--pagegrid-col-verywide-outsidegutter);
  --pagegrid-col-verywide-outsidegutter-bg: var(--pagegrid-col-verywide-outsidegutter);
  --pagegrid-col-text-edge-left: 2;
  --pagegrid-col-text-edge-right: 27;
  --pagegrid-col-narrow-gutter-right: 22 / span 5; /* contentarea at the right of narrow layout */
  --pagegrid-col-verywide-left: 3 / 14;
  --pagegrid-col-verywide-right: 15 / 26;
  --pagegrid-col-withsidebar-sidebar: 3 / 9;
  --pagegrid-col-withsidebar-content: 10 / 26;
  --pagegrid-col-withsidebar-tabs: 10 / 26; /* tabs are always allowed to use the full horizontal space



--pagegrid-col-viewportstart-to-middle:  1 / 14;
--pagegrid-col-middle-to-viewportend:   15 / 28;

// focus in some sites reach to the end of the viewport,
// in 4TU it ends at the content end
/*
Focus with these traits:
- FOCUS (image) up till exact middle
- Ends at content end

--pagegrid-col-verywide-focusleft:           3 / 15;
--pagegrid-col-verywide-right-extraspacing: 17 / 26;

--pagegrid-col-verywide-focusright:       15 / 26;
--pagegrid-col-verywide-left-extraspacing: 3 / 13; / * keep an extra column spacing * /
*/
  /*
  Focus with these traits
  - gutter in the middle is clear in both left/right focus mode
  -
  */
  --pagegrid-col-verywide-focusleft: 3 / 14;
  --pagegrid-col-verywide-focusleftfull: 2 / 14;
  --pagegrid-col-verywide-right-extraspacing: 15 / 26;
  --pagegrid-col-verywide-focusright: 15 / 26;
  --pagegrid-col-verywide-focusrightfull: 15 / 27;
  --pagegrid-col-verywide-left-extraspacing: 3 / 14; /* keep an extra column spacing */
  --widget-cta-focusleft-text-col: 13 / 26;
  --widget-cta-focusleft-image-col: 2 / 12;
  --widget-cta-focusright-text-col: 2 / 16;
  --widget-cta-focusright-image-col: 17 / 27;
  --pagegrid-col-fullwidth: 1 / 28;
  --zindex-overlap-deeplinks: 10;
  --zindex-overlap-contentarea: 11;
}

@media (max-width: 1200px) {
  html {
    --pagegrid-col-verynarrow: var(--pagegrid-col-narrow);
  }
}
@media (max-width: 1023px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
    --pagegrid-col-narrow: var(--pagegrid-col-verywide);
    --pagegrid-col-verynarrow: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  html {
    --pagegrid-col-default: var(--pagegrid-col-narrow);
    --pagegrid-col-default-left: var(--pagegrid-col-narrow-left);
    --pagegrid-col-default-right: var(--pagegrid-col-narrow-right);
    --pagegrid-col-default-focusleft-left: 6 / span 10;
    --pagegrid-col-default-focusleft-right: 17 / span 6;
    --pagegrid-col-default-focusright-left: 6 / span 6;
    --pagegrid-col-default-focusright-right: 13 / span 10;
  }
  /*
      .contentareaheader__title
    , .contentareaheader__when
    , .contentareaheader__source
    {
      grid-column: 4 / span 18;
    }
  */
}
/* when 100dvh == $pagegrid-verywide-maxwidth */
/*@media (max-width: 100dvh)*/
@media (max-width: 1170px) {
  html {
    --contentpanels-radius-outsidegutter: 0px !important;
    --contentpanels-radius-large-viewport-edge: 0px;
    --contentpanels-radius-medium-viewport-edge: 0px;
    --contentpanels-radius-small-viewport-edge: 0px;
    --pagegrid-col-text-edge-left: 3;
    --pagegrid-col-text-edge-right: 26;
  }
  .pageheader--sidebyside .pageheader__background,
  .pageheader__background,
  .footer__panel__background,
  .header-searchpanel-top,
  .header-searchpanel-bottom {
    --pagegrid-col-verywide-outsidegutter: 1 / -1;
  }
  .acoifilterpage.acoifilterpage .pageheader--type-search.pageheader .pageheader__background {
    grid-column: 1/-1;
  }
  /*
      .header-menubar__content
    , body:has(> header) .header-crumblepath__content
    {
      padding-left: 15px;
    }
  */
  .footer--zvi.footer--zvi {
    margin-bottom: 0;
  }
  /* Don't use the negative margin tricks at the sides of the page
     when we are already at the edge. */
  .knowledgebase-grid__background.knowledgebase-grid__background {
    margin-left: 0;
    margin-right: 0;
    grid-column: var(--pagegrid-col-fullwidth);
  }
}
body {
  display: grid;
  min-height: 100vh;
  grid-template-rows: var(--pagegrid-rowsize-header-top) minmax(0, min-content) minmax(0, min-content) auto 1fr minmax(0, min-content);
  grid-template-columns: var(--pagegrid-template-columns);
}

.header-top-background,
.header-top {
  z-index: 1;
}

.deeplinks-wrapper.deeplinks-wrapper.deeplinks-wrapper {
  z-index: 9; /* keep over page content */
}

.site-scrollprogressbar {
  z-index: 10;
}

.header-menubar-background {
  z-index: 11;
}

.header-menubar,
.header-filterbar__querycontainer .wh-autocomplete-container {
  z-index: 12;
}

.wh-autocomplete-values {
  z-index: 11;
}

.header-menubar:hover {
  z-index: 15;
}

.header-menubar:hover ~ .header-menubar-background {
  z-index: 14;
}

.page__contentarea__inset {
  --pagegrid-inset-zindex: 1;
}

.deeplinks-wrapper {
  opacity: 1;
  transition: opacity 0.2s;
}

.deeplinks-wrapper.hide_deeplinks {
  opacity: 0;
  pointer-events: none;
}

.page-header__content {
  position: relative;
  z-index: 1;
}

.footer {
  grid-row: 5;
  grid-column: var(--pagegrid-col-fullwidth);
}

.header-top {
  grid-row: 1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter-bg);
}

.header-top-background {
  grid-row: 1;
}

.header-menubar-background {
  grid-row: 2;
}

.header-menubar {
  grid-row: 2;
  grid-column: 1;
  grid-column: var(--pagegrid-col-fullwidth);
}

.page-header__background {
  grid-row: 1/span 3;
  grid-column: var(--pagegrid-col-fullwidth);
  background-color: #F2EFED;
}

.header-crumblepath {
  grid-row: 3;
}

.pageheader {
  grid-row: 4;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page-header__content {
  grid-row: 4;
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
}

.page__body {
  grid-row: 5;
  grid-column: var(--pagegrid-col-fullwidth);
}

.footer {
  grid-row: 6;
  grid-column: var(--pagegrid-col-fullwidth);
}