/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.widget-accordion {
  grid-column: var(--pagegrid-col-fullwidth);
}

.widget-accordion__item {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: min-content min-content;
}

.widget-accordion__item__header {
  grid-column: var(--pagegrid-col-default);
}

.widget-accordion__item__body {
  grid-column: var(--pagegrid-col-fullwidth);
}

.widget-accordion input {
  display: none;
}

.widget-accordion__description {
  margin-bottom: 15px;
}

.widget-accordion__item:last-child::after {
  grid-column: var(--pagegrid-col-default);
  content: "";
  display: block;
  border-bottom: 1px solid #C5C5C5;
}

.widget-accordion__item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #C5C5C5;
  color: #000000;
  min-height: 49px;
  padding: 6px 0;
  font: 600 17px/20px var(--rtd-heading-fontfamily);
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.widget-accordion__item__header::after {
  margin-left: auto;
  color: #666666;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f067";
  margin-left: 20px;
  margin-right: 20px;
}

.widget-accordion__item__header:hover {
  background-color: rgba(255, 255, 255, 0.03);
}

.widget-accordion__item__body {
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.5s;
}

input:checked + .widget-accordion__item .widget-accordion__item__header::after {
  content: "\f068";
}

input:checked + .widget-accordion__item .widget-accordion__item__body {
  height: auto;
  opacity: 1;
}

.widget-accordion__item__body__content {
  padding-bottom: 22.5px;
}