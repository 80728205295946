html.page--tt-category.page--tt-category .widget-richcontent {
  /* Extra space inbetween */
  --pagegrid-col-verywide-left: 3 / 14;
  --pagegrid-col-verywide-right: 17 / 26;
}

html.page--tt-category .folderwithfilters__items--listview .projectblock-description__text {
  -webkit-line-clamp: 2;
}

/* When a CTA widget starts directly below an image header
   we want it to stick to the header with a grey background around it. */
.page-header--withimage + .page__body .page-contentstart + .widget-cta {
  --appearance: "focus-bottom-right";
  --header-to-content-margin: 0;
  --pagegrid-col-verywide-left: 3 / 16;
  --pagegrid-col-verywide-right: 17 / 26;
  --height-compensation: 0px;
  --cta-img-width: clamp(195px, calc(100vw * 0.4036), 553px);
  --cta-img-margin-top: 16px;
  --cta-img-stickout: 30px;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: 30px min-content var(--cta-img-stickout) 20px;
  /* Emulate a "float at the bottom-right", which isn't strictly possible.
     (only with some CSS trickery or JS code support) */
  /*
    .widget-cta__text::before
    {
      content: "";
      float: right;
      width: 0px;

      height: calc(100% - var(--cta-img-width) / 16 * 9);

      //outline: 2px solid #000;
    }

    .widget-cta__text__reservespace
    {
      clear: right;
      content: "";
      float: right;
      width: var(--cta-img-width);
      aspect-ratio: 16 / 9;
      // outline: 2px solid #FFF;

      margin-bottom: 32px; / * Prevent Safari & Firefox trying to wrap below the image while there isn't space for a line under this space * /
      // margin-bottom: -30px;
    }


    .widget-cta__imagewrapper
    {
      z-index: 2; / * For Firefox * /
      margin-top: 30px;
      grid-column: var(--pagegrid-col-verywide-right);
      grid-row: 2 / span 2;
      align-self: end;
      width: 100%;
      margin: 0;
    }
  */
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__content-background {
  background: var(--currenttheme-background);
  content: "";
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 2/span 1;
  height: 100%;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__content {
  display: contents !important;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__content-background, .page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__imagewrapper,
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__text {
  grid-row: 2;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__background {
  z-index: -1;
  grid-column: var(--pagegrid-col-verywide-outsidegutter-bg);
  grid-row: 1/5;
  background-color: #F5F5F5;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__text {
  padding-top: 0;
  padding-bottom: 0;
  grid-column: var(--pagegrid-col-verywide);
  padding-right: 0; /* we want our .widget-cta__text__reservespace to stick to the right of the __text container */
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__imagewrapper {
  z-index: 2; /* For Firefox */
  grid-column: var(--pagegrid-col-verywide-right);
  grid-row: 2/span 2;
  align-self: end;
  width: 100%;
  margin: 0;
  margin-top: 30px;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__text__reservespace {
  float: right;
  width: var(--cta-img-width);
  height: calc(100% + var(--cta-img-stickout));
  display: flex;
  align-items: flex-end;
  shape-outside: inset(calc(100% - var(--height-compensation) - var(--cta-img-margin-top) - var(--cta-img-width) / 16 * 9) 0 0);
  margin-left: 30px;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__title {
  padding-top: var(--widget-cta-sidebyside-pad-top);
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__description {
  margin-top: 0 !important;
  padding-top: 20px;
}
.page-header--withimage + .page__body .page-contentstart + .widget-cta .widget-cta__description::after {
  display: block;
  content: "";
  height: var(--widget-cta-sidebyside-pad-bottom);
}