/* Stretch page and push footer to end of viewport or page ******************************/
html {
  /* we cannot use a min-height on flex in IE,
     but we can set a fixed 100vh height here and by making it
     flex have body inherit the height but allow it to overflow (but not shrink) using 1 0 auto;
  */
  display: flex;
  min-height: 100vh;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: visible;
}

body > * {
  flex: none;
}

.page__body {
  flex: 1 0 auto;
}

/*****************************************************************************************/