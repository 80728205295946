/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
There are 2 types of chapter pages:
- Toolkit Chapter
- Resource Chapter
*/
.page--toolkit-chapter {
  --rtd-h1-font: bold 50px/55px var(--rtd-heading-fontfamily);
}
@media (max-width: 767px) {
  .page--toolkit-chapter .arrowtabs {
    display: none;
  }
}
@media (min-width: 768px) {
  .page--toolkit-chapter .ttresource-sidebar__menu {
    display: none;
  }
  .page--toolkit-chapter .pageheader-bottombar__menuwrapper {
    display: none;
  }
}

.page--resource-chapter {
  --rtd-h1-font: bold 38px/46px var(--rtd-heading-fontfamily);
  --rtd-h1-font: bold clamp(28px, 3.2vw, 38px)/110% var(--rtd-heading-fontfamily);
  /* When not centering, we get the page H1 just below the chapter title in the pageheader-bottombar.
     (and additionally there may be a h2 below that h1 so we have three black headings in a row)
     We will make the h1 red so we don't have a stack a black titles - the pagetitle (h1) will get the focus.
  */
}
@media (max-width: 1023px) {
  .page--resource-chapter h1 {
    color: var(--color-red);
  }
}
@media (min-width: 1024px) {
  .page--resource-chapter .pageheader-bottombar__menuwrapper {
    display: none;
  }
}

.page--tt2-chapterpage h3 {
  margin-top: var(--header-to-content-margin);
  text-transform: none;
}

.pagenavigation {
  --button-color: var(--color-red);
  --button-color-hover: var(--color-red-hover);
}

.pagenavigation .wh-form__button--next {
  margin-left: auto;
}

@media (min-width: 1024px) {
  .pagenavigation {
    margin-top: 80px;
    border-top: 1px solid var(--color-red);
    padding-top: 25px;
    display: flex;
    justify-content: space-between;
    --button-color: var(--color-red);
    --button-color-hover: var(--color-red-hover);
  }
  .pagenavigation .wh-form__button {
    width: 175px;
  }
}
@media (max-width: 1023px) {
  .pagenavigation {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
  }
  .pagenavigation .wh-form__button {
    width: 140px;
  }
}