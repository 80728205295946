/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.spc-modalitylayer {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  pointer-events: none;
}

.sidemainmenu {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  width: 440px;
  max-width: 100%;
  transform: translateX(-100%);
  transition: transform 0.25s cubic-bezier(0, 0, 0.3, 1);
  visibility: hidden;
}

.sidemainmenu__content {
  position: relative;
  height: 100%;
  background-color: #000000;
  color: #FFFFFF;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 25px;
}

#showsidemainmenu:checked ~ .spc-modalitylayer {
  opacity: 0.5;
  pointer-events: auto;
}

#showsidemainmenu:checked ~ .sidemainmenu {
  transform: translateX(0%);
  visibility: visible;
}

.sidemainmenu__closebutton {
  position: absolute;
  top: 0;
  right: 0;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  color: #666666;
  /*
      color: #c72125;
      color: var(--color-secondary);
  */
  stroke-width: 3;
  cursor: pointer;
}

@media (min-width: 600px) {
  .sidemainmenu__closebutton {
    z-index: -1;
    transform: translate(0%);
  }
  #showsidemainmenu:checked ~ .sidemainmenu .sidemainmenu__closebutton {
    transform: translate(100%);
    transition: transform 0.25s;
    transition-delay: 0.25s;
  }
}
.sidemainmenu__closebutton svg {
  width: 24px;
  height: 24px;
}

.sidemainmenu__closebutton:hover {
  color: var(--color-theme-darker);
}

.sidemainmenu__logo {
  display: flex;
  align-items: center;
}

.sidemainmenu__logoimage {
  width: 90px;
  height: 38px;
}

.sidemainmenu__logotext {
  font-size: 8px;
  line-height: 8.8px;
  font-family: var(--rtd-text-fontfamily);
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 6px;
}

ul.sidemainmenu-level {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 25px;
}

.sidemainmenu a {
  color: inherit;
  text-decoration: none;
}

.sidemainmenu-level1__itemlink {
  display: flex;
  align-items: baseline;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
}

.sidemainmenu-level1__item + .sidemainmenu-level1__item {
  margin-top: 10px;
}

.sidemainmenu-level1__itemlink .icon {
  width: 13px;
  height: 13px;
  margin-right: 10px;
}

.sidemainmenu-level1__itemlink:hover {
  color: var(--color-theme);
}

.sidemainmenu-level1__itemlink--selected,
.sidemainmenu-level2__itemlink--selected,
.sidemainmenu-level3__itemlink--selected {
  color: var(--color-theme-darker);
}

.sidemainmenu-level2__item {
  font-size: 16px;
}

.sidemainmenu-level3__item {
  font-size: 14px;
}