.filterfolder__tagcategories .tagcategory {
  padding: 0; /* override .tagcategory in projectsoverview.scss */
}

.filterfolder__tagcategories .tagcategory select {
  width: 100%;
}

.filterfolder__tagcategories {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 30px;
  row-gap: 15px;
}

/*
.filterfolder__tagcategories
{
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 15px;
}

@media (min-width: 601px)
{
  .filterfolder__tagcategories .tagcategory
  {
    flex: 1 1 270px; / * we prefer 270px, but it may shrink a little * /
    min-width: 220px;
    max-width: min(400px, 50%);
  }
}

@media (max-width: 600px)
{
  .filterfolder__tagcategories .tagcategory
  {
    flex: 1 0 180px;
    max-width: clamp(400px, 100%, 550px);
  }
}
*/