/*
NOTE: Original design used these colors:
Previous   - #C72125
Current    - #74237D (purple)
Next steps - #DB7072 (red 50%)
*/

.arrowtabs
{
  --component-tabstrip-background:      transparent;
  --component-tabstrip-height:          88px;
  --component-tabstrip-spacing:         0;
  --component-tabstrip-font:            bold 22px/24px var(--rtd-heading-fontfamily);
/*  --component-tabstrip-selected-font:   bold 22px/24px var(--rtd-heading-fontfamily);*/
  --component-tabstrip-item-selected-font: bold 22px/24px var(--rtd-heading-fontfamily);

  --component-tabstrip-item-padleft:    15px;
  --component-tabstrip-item-padright:   22px;


  --component-tabstrip-item-color:      #FFFFFF;
  --component-tabstrip-item-background: #C72125;
  --component-tabstrip-item-border:     none;

  --component-tabstrip-item-hover-color:          #FFFFFF;
  --component-tabstrip-item-hover-background:     var(--color-red-hover);
  --component-tabstrip-item-hover-border:         none;

  --component-tabstrip-item-selected-color:       #FFFFFF;
/*  --component-tabstrip-item-selected-background:  var(--color-theme-darker);*/
  --component-tabstrip-item-selected-background:  var(--color-purple);
  --component-tabstrip-item-selected-border:      none;
  --component-tabstrip-item-selected-underlining-color: var(--color-theme);
}

.arrowtabs
{
  --arrowstabs-arrow-width:     64px;
  --arrowstabs-gap:             16px;
  --arrowstabs-arrow-padleft:   25px;

  --header-to-content-margin: 0; /* stick to header */
}


@media (max-width: 1000px)
{
  .arrowtabs
  {
    --arrowstabs-arrow-width:     32px;
    --arrowstabs-gap:             10px;
    --arrowstabs-arrow-padleft:   15px;
    --component-tabstrip-height:  80px;
    --component-tabstrip-font:    bold 21px var(--rtd-heading-fontfamily);
  }
}

@media (max-width: 768px)
{
  .arrowtabs
  {
    --arrowstabs-arrow-width:     30px;
    --arrowstabs-gap:             10px;
    --arrowstabs-arrow-padleft:   15px;
    --component-tabstrip-height:  65px;
    --component-tabstrip-font:    bold 19px var(--rtd-heading-fontfamily);
  }

  .arrowtabs .pagetabs__content > *:hover
  {
    text-underline-offset: 3px;
  }
}

@media (max-width: 700px)
{
  .arrowtabs
  {
    --arrowstabs-arrow-width:     20px;
    --arrowstabs-gap:              7px;
    --arrowstabs-arrow-padleft:   10px;
    --component-tabstrip-height:  55px;
    --component-tabstrip-font:    bold 18px var(--rtd-heading-fontfamily);
  }

  .arrowtabs .pagetabs__content > *:hover
  {
    text-underline-offset: 3px;
  }
}


/*.pagetabs.pagetabs--tabs.arrowtabs*/
.arrowtabs.arrowtabs.arrowtabs
{
/*  grid-column: var(--pagegrid-col-verywide-outside-gutter);*/
  grid-column: var(--pagegrid-col-fullwidth);
}

.arrowtabs.arrowtabs.arrowtabs .pagetabs__background
{
  grid-column: var(--pagegrid-col-fullwidth);

  background: #F5F5F5;
  height: 50%;
}

.arrowtabs.arrowtabs .pagetabs__content
{
  margin-left: 0;
}

.arrowtabs.arrowtabs .pagetabs__content > *
{
  clip-path: polygon(0% 0%, calc(100% - var(--arrowstabs-arrow-width)) 0%, 100% 50%, calc(100% - var(--arrowstabs-arrow-width)) 100%, 0% 100%, 0% 0%);
  flex: 1 0 auto;
  --component-tabstrip-item-padleft: var(--arrowstabs-arrow-padleft);

  justify-content: start;
  text-transform: none;
}

.arrowtabs.arrowtabs .pagetabs__content > * + *
{
/*  margin-left: -48px;*/
/*   64-48;*/
  margin-left: calc(-1 * var(--arrowstabs-arrow-width) + var(--arrowstabs-gap));

  clip-path: polygon(0% 0%, calc(100% - var(--arrowstabs-arrow-width)) 0%, 100% 50%, calc(100% - var(--arrowstabs-arrow-width)) 100%, 0% 100%, var(--arrowstabs-arrow-width) 50%, 0% 0%);
  --component-tabstrip-item-padleft: calc(var(--arrowstabs-arrow-padleft) + var(--arrowstabs-arrow-width));
}

.arrowtabs.arrowtabs .pagetabs__content > a:hover
{
  text-decoration: underline;
}

.arrowtabs.arrowtabs .pagetabs__content > *::after
{
  display: none;
}
