/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
.filteredoverview__teaser {
  /* <dialog> */
  padding: 0;
  border: 0;
  margin-top: auto;
  margin-bottom: 0;
  position: fixed;
  position: -webkit-sticky;
  position: sticky;
  margin-top: auto;
  z-index: 99999;
  bottom: 0;
  width: 100%;
  background-color: var(--color-blue);
  box-shadow: 3px 2px 4px 6px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  grid-column: var(--pagegrid-col-fullwidth) !important;
}

a.filteredoverview__teaser {
  text-decoration: none;
}

.filteredoverview__teaser__content {
  position: relative;
  height: 64px;
  display: flex;
  align-items: center;
}

html.filteredoverview--showfilters .filteredoverview__teaser {
  opacity: 1;
  pointer-events: auto;
}

/*
.filteredoverview__teaser--aboveresults
{
  opacity: 1;
  pointer-events: auto;
}
*/
.filteredoverview__teaser__button {
  background: none;
  border: 0;
  margin: 0;
  cursor: pinter;
  font: 600 18px/30px var(--rtd-text-fontfamily);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  text-transform: uppercase;
  transition: background-color 0.25s;
}
.filteredoverview__teaser__button:hover {
  background-color: var(--color-blue-hover);
}
.filteredoverview__teaser__button:focus {
  box-shadow: 0 0 0 2px #000;
  outline: 0;
}
@supports (outline: -webkit-focus-ring-color auto 5px) {
  .filteredoverview__teaser__button:focus {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }
}
.filteredoverview__teaser__button:active:focus {
  box-shadow: none;
  outline: none;
}

.filteredoverview__teaser__label::after {
  content: ">";
  margin-left: 15px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f105";
  font-size: 23px;
  margin-top: 2px;
}

.filteredoverview__teaser__close {
  position: absolute;
  right: 15px;
  font: 15px var(--rtd-text-fontfamily);
  color: #FFFFFF;
  padding: 6px 14px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}
.filteredoverview__teaser__close:hover {
  background-color: var(--color-blue-hover);
}

.filteredoverview__teaser__close::after {
  margin-left: 15px;
  font-size: 20px;
  content: ">";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00d";
}

@media (max-width: 640px) {
  .filteredoverview__teaser__close {
    display: none;
  }
  .filteredoverview__teaser__label {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 375px) {
  .filteredoverview__teaser__label {
    padding-left: 30px;
    padding-right: 30px;
  }
}