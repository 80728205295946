html {
  --header-to-content-margin: 50px;
  /** Default whitespace between header and content
      In some cases (heading image followed by content with fullwidth color background)
      this margin will be removed.
  */
  --whitespace-footer-content: 90px;
}
@media (max-width: 768px) {
  html {
    --header-to-content-margin: 30px;
  }
}

/*
html
{
  --header-to-content-margin: var(--header-plain-margin-to-content);
}
*/