.pageheader-bottombar {
  grid-row: 6; /* below the image */
  height: 100px;
  height: clamp(60px, 10vw, 100px);
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.pageheader-bottombar::before {
  grid-column: var(--pageheader-background-col);
  grid-row: 1;
  content: "";
  background: #F5F5F5;
}

.pageheader-bottombar--tabs {
  height: clamp(60px, 12vw, 80px);
  border-bottom: 1px solid #C5C5C5;
}

.pageheader-bottombar--tabs .pageheader-bottombar__content {
  grid-column: var(--pagegrid-col-default);
  align-self: end;
  margin-bottom: -1px;
}

.pageheader-bottombar--title .pageheader-bottombar__content {
  display: flex;
  align-items: center;
}

.pageheader-bottombar-title {
  color: #565656;
  font: var(--pageheader-bottombar-title-font);
  text-transform: uppercase;
}

.pageheader-bottombar__content {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1;
  display: flex;
  align-items: center;
}

.pageheader-searchbar__slogan {
  font: bold 34px/37px var(--rtd-heading-fontfamily);
  font-size: clamp(20px, 3vw, 34px);
  text-transform: uppercase;
  color: #000000;
}