/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.header-menubar__content a.header-menubar__selected {
  background-color: var(--color-theme-darker);
}

ul.spc-menubar {
  display: flex;
  height: 100%;
}

ul.spc-menubar,
.spc-menubar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.spc-menubar > li {
  position: relative;
  display: flex;
  align-items: center;
}

ul.spc-menubar > li + li {
  margin-left: 0;
}

ul.spc-menubar > li > ul {
  position: absolute;
  z-index: 5;
  background-color: #FFFFFF;
  box-shadow: 0 0 7px 6px rgba(0, 0, 0, 0.15);
  color: #000000;
  padding: 0 0;
  bottom: 0;
  transform: translate(0, 100%);
  left: 0;
  min-width: 200px;
  max-width: 400px;
  display: none;
}

ul.spc-menubar > li:hover > ul {
  display: block;
}

.spc-menubar a {
  /*
  // Keep all items single-line, use ellipsis if needed
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  */
}

ul.spc-menubar > li > a {
  color: #FFFFFF;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  letter-spacing: 0.25px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

ul.spc-menubar > li > a:hover {
  background-color: var(--color-red-hover);
  text-decoration: underline;
}

/*
ul.spc-menubar > li > a::after
{
  display: block;
  content: attr(data-title) ".";
  font-weight: bold;
  height: 0;
  overflow: hidden;
  visibility: hidden;

  letter-spacing: 0px;
}

// FIXME: media hover:hover ?
ul.spc-menubar > li > a:hover
{
  font-weight: bold;
  letter-spacing: 0px;
  // border-bottom: 1px solid $color-theme-cta;
}
*/
ul.spc-menubar > li > ul > li > a {
  color: #000000;
  text-decoration: none;
  display: block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
}

ul.spc-menubar > li > ul > li > a:hover {
  text-decoration: none;
  background-color: #EAEAEA;
}

ul.spc-menubar > li > ul > li + li {
  margin-top: 0px;
}