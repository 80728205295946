/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.widget-richcontent {
  grid-column: var(--pagegrid-col-fullwidth);
}
.widget-richcontent.widget--background-lightgrey {
  background-color: var(--color-lightgrey);
  padding-top: 32px;
  padding-bottom: 32px;
}
.widget-richcontent.widget--background-blue, .widget-richcontent.widget--background-bluegradient, .widget-richcontent.widget--background-red, .widget-richcontent.widget--background-redgradient {
  padding-top: 32px;
  padding-bottom: 32px;
}
.widget-richcontent.widget--background-blue p, .widget-richcontent.widget--background-blue ol, .widget-richcontent.widget--background-blue ul, .widget-richcontent.widget--background-blue h1, .widget-richcontent.widget--background-blue h2, .widget-richcontent.widget--background-blue h3, .widget-richcontent.widget--background-bluegradient p, .widget-richcontent.widget--background-bluegradient ol, .widget-richcontent.widget--background-bluegradient ul, .widget-richcontent.widget--background-bluegradient h1, .widget-richcontent.widget--background-bluegradient h2, .widget-richcontent.widget--background-bluegradient h3, .widget-richcontent.widget--background-red p, .widget-richcontent.widget--background-red ol, .widget-richcontent.widget--background-red ul, .widget-richcontent.widget--background-red h1, .widget-richcontent.widget--background-red h2, .widget-richcontent.widget--background-red h3, .widget-richcontent.widget--background-redgradient p, .widget-richcontent.widget--background-redgradient ol, .widget-richcontent.widget--background-redgradient ul, .widget-richcontent.widget--background-redgradient h1, .widget-richcontent.widget--background-redgradient h2, .widget-richcontent.widget--background-redgradient h3 {
  color: #FFFFFF;
}
.widget-richcontent.widget--background-red {
  background-color: var(--color-red);
}
.widget-richcontent.widget--background-redgradient {
  background-image: linear-gradient(90deg, #7B3F3C 0%, #341D1C 100%);
}
.widget-richcontent.widget--background-blue {
  background-color: var(--color-blue);
}
.widget-richcontent.widget--background-bluegradient {
  background-image: linear-gradient(90deg, #14143C 0%, #28286C 100%);
}

.widget-richcontent__content {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

@media (max-width: 599px) {
  .widget-richcontent__content {
    display: block;
    padding-left: 10px;
    padding-right: 10px;
  }
  .widget-richcontent__column + .widget-richcontent__column {
    margin-top: 15px;
  }
  .widget-richcontent__column:first-child > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column:last-child > *:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 600px) {
  .widget-richcontent .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-default-left);
  }
  .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-default-right);
  }
}
@media (min-width: 1000px) {
  .widget-richcontent .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-default-left);
  }
  .widget-richcontent .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-default-right);
  }
  .widget-richcontent--focusleft .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-default-focusleft-left);
  }
  .widget-richcontent--focusleft .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-default-focusleft-right);
  }
  .widget-richcontent--focusright .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-default-focusright-left);
  }
  .widget-richcontent--focusright .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-default-focusright-right);
  }
  .widget-richcontent--wide .widget-richcontent__column:nth-child(1) {
    grid-column: var(--pagegrid-col-verywide-left);
  }
  .widget-richcontent--wide .widget-richcontent__column:nth-child(2) {
    grid-column: var(--pagegrid-col-verywide-right);
  }
  .widget-richcontent--equalheight .widget-richcontent__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.widget-richcontent .widget-richcontent .widget-richcontent__column.widget-richcontent__column:nth-child(1) {
  grid-column: 1/span 9;
}
.widget-richcontent .widget-richcontent .widget-richcontent__column.widget-richcontent__column:nth-child(2) {
  grid-column: 11/span 9;
}