.acoifilterpage__results {
  --gridimage-aspectratio: 4 / 3; /* fallback in case it's not specified */
}

.folderwithfilters__items--gridview {
  grid-column: var(--pagegrid-col-verywide);
  margin-left: -4px;
  margin-right: -4px;
  padding: 4px;
}

.folderwithfilters__items--gridview {
  grid-gap: var(--grid-spacing);
  gap: var(--grid-spacing);
}
@media (max-width: 767px) {
  .folderwithfilters__items--gridview {
    display: grid;
    grid-template-columns: 100%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .folderwithfilters__items--gridview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (min-width: 1000px) {
  .folderwithfilters__items--gridview {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}
@media (min-width: 1200px) {
  .folderwithfilters__items--gridview[data-gridmaxcols="4"] {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  }
}