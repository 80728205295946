.page--folderwithfilters--list .page__contentarea > .folderwithfilters__rtdcontent {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
.page--folderwithfilters--list .folderwithfilters__rtdcontent > * {
  grid-column: var(--pagegrid-col-default);
}

.folderwithfilters__items--listview {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  flex-direction: column;
  /*
    .widget-ctablocks__description
    {
      padding: 15px;
      font-family: var(--rtd-text-fontfamily);
      font-size: 14px;
      line-height: 20px;
      color: var(--rtd-text-color);
    }
  */
}
.folderwithfilters__items--listview .projectblock {
  z-index: 0;
  text-decoration: none;
  margin: 0 calc(var(--component-spctile-padding-h) * -1);
  padding: var(--component-spctile-padding-v) var(--component-spctile-padding-h);
}
.folderwithfilters__items--listview .projectblock:focus {
  outline: 3px solid var(--color-blue);
  outline-offset: 2px; /* have some offset because the outline might not have a good contrast with all blocks */
}
.folderwithfilters__items--listview .projectblock.notmatching {
  display: none !important;
}
.folderwithfilters__items--listview a.projectblock.projectblock {
  text-decoration: none;
}
.folderwithfilters__items--listview .projectblock:hover {
  background: #F5F5F5;
}
.folderwithfilters__items--listview .projectblock:hover .projectblock-image {
  transform: scale(1.05);
}
.folderwithfilters__items--listview .projectblock-top {
  grid-column: 1;
  grid-row: 1/span 2;
  flex: none;
  position: relative;
  overflow: hidden;
  width: var(--resultslist-thumbnail-width);
  aspect-ratio: var(--gridimage-aspectratio);
}
.folderwithfilters__items--listview .projectblock-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: var(--gridimage-aspectratio);
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}
.folderwithfilters__items--listview .projectblock-meta {
  grid-column: 3;
  grid-row: 1;
  flex: 1 1 0;
  word-break: break-word; /* text must allways fix */
  z-index: 2;
}
.folderwithfilters__items--listview .projectblock-when {
  color: #000000;
  font: 600 16px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  margin-bottom: 2px;
}
.folderwithfilters__items--listview .projectblock-title {
  color: var(--color-red);
  font: bold 16px/22px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}
.folderwithfilters__items--listview .projectblock:hover .projectblock-title {
  text-decoration: underline;
}
.folderwithfilters__items--listview .projectblock-description {
  grid-column: 3;
  grid-row: 2;
  font: 15px/24px var(--rtd-text-fontfamily);
  color: #666666;
}
.folderwithfilters__items--listview .projectblock-description__text {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.folderwithfilters__items--listview.folderwithfilters__items--withimages .projectblock {
  display: grid;
  grid-template-columns: var(--resultslist-thumbnail-width) 30px 1fr;
  grid-template-rows: min-content 1fr;
}
.folderwithfilters__items--listview.folderwithfilters__items--withimages .projectblock::after {
  content: "";
  grid-column: 1;
  grid-row: 1/span 2;
  background: radial-gradient(circle, rgba(0, 103, 180, 0) 25%, rgba(0, 103, 180, 0.3) 100%), radial-gradient(circle, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.6) 100%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;
}
.folderwithfilters__items--listview.folderwithfilters__items--withimages .projectblock:hover::after {
  content: "";
  opacity: 1;
}

.folderwithfilters__items--listview.folderwithfilters__items--noimages .projectblock {
  display: grid;
  grid-template-columns: min-content 30px 1fr;
  grid-template-rows: min-content 1fr;
}
.folderwithfilters__items--listview.folderwithfilters__items--noimages .projectblock::before {
  grid-column: 1;
  grid-row: 1;
  align-self: baseline;
  content: "";
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  margin-right: 15px;
}
.folderwithfilters__items--listview.folderwithfilters__items--noimages .projectblock-meta {
  align-self: baseline;
}