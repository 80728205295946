.projects__results {
  grid-column: var(--pagegrid-col-verywide);
  background-color: var(--color-lightgrey);
}

.tueproject {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  text-decoration: none;
  padding-bottom: 18px;
}

.tueproject__top {
  margin-bottom: 15px;
  position: relative;
  background-color: #EEEEEE;
  overflow: hidden;
}

.tueproject__top::before {
  display: block;
  content: "";
  padding-top: 56.25%;
}

.tueproject__top__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 56.25%;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}

.tueproject:hover {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.tueproject:hover .tueproject__top__image {
  transform: scale(1.1);
}
.tueproject:hover .tueproject__title {
  color: var(--color-red);
}

.tueproject__top__image.b-lazy {
  opacity: 0;
}

.tueproject__top__image.b-lazy.b-loaded {
  opacity: 1;
}

.tueproject__title,
.tueproject__description,
.tueproject__tags {
  padding-left: 15px;
  padding-right: 15px;
}

.tueproject__title {
  font: bold 18px/24px var(--rtd-heading-fontfamily);
  color: #000000;
}

.tueproject__description {
  margin-top: 17px;
  margin-bottom: 30px;
  color: #666666;
  font: 14px/20px var(--rtd-text-fontfamily);
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  /*
  display: -webkit-box; // ONLY with -webkit-box
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  */
}

.tueprojecttag {
  display: inline-block;
  white-space: nowrap;
  padding-left: 12px;
  padding-right: 12px;
  background-color: #DDDDDD;
  color: #666666;
  height: 28px;
  line-height: 28px;
  font: 12px/28px var(--rtd-text-fontfamily);
  border: 1px solid transparent;
}

.tueproject__tags {
  margin-top: auto;
  display: flex;
  flex-wrap: wrap;
}

.tueproject .tueproject__tags {
  margin-bottom: -11px;
  margin-left: -10px;
}

.tueproject .tueprojecttag {
  margin-bottom: 11px;
  margin-left: 10px;
}

a.tueprojecttag {
  text-decoration: none;
}

.tueprojecttag--free {
  background-color: #FFFFFF;
  border: 1px solid #C8C8C8;
  color: #666666;
}

.tueprojecttag--status {
  background-color: #666666;
  color: #FFFFFF;
}

.tueprojecttag--category {
  background: var(--color-red);
  color: #FFFFFF;
}

a.tueprojecttag:hover {
  border: 1px solid #666666;
}

a.tueprojecttag--status:hover {
  background-color: var(--color-theme-darker);
  border-color: transparent;
}