.page-header__meta .pageheader-searchbar__inputwrapper {
  flex: 4 1 0px;
  align-self: center;
}
.page-header__meta .pageheader-searchbar__button {
  color: var(--color-red);
}

.pageheader-bottombar .pageheader-searchbar__inputwrapper {
  position: relative;
  margin-left: auto;
  flex: 1 0 150px;
  max-width: min(45vw, 350px);
}

.pageheader-searchbar__input.pageheader-searchbar__input {
  height: 48px;
  padding-right: 40px;
}

.pageheader-searchbar__button {
  padding: 0 17px;
}

.pageheader-searchbar__inputwrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.pageheader-searchbar__input.pageheader-searchbar__input {
  width: 100%;
  border: 1px solid #9D9D9D;
  border-radius: 5px;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.12);
}

.pageheader-searchbar__button {
  background: none;
  border: 0;
  height: 100%;
  transform: scaleX(-1);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  color: var(--color-purple);
}

.pageheader-searchbar__button::before {
  content: var(--fa-search);
  font: var(--fontawesome-solid);
  font-size: 21px;
}

.pageheader-searchbar__inputwrapper,
.pageheader-searchbar__input,
.wh-autocomplete-container {
  font: 16px var(--rtd-text-font);
}

html.page--tt-categoriesoverview.page--searching .categoryblocks {
  display: none;
}

html.page--tt-categoriesoverview:not(.page--searching) .searchresults {
  display: none;
}

html.page--searching .pageheader-searchbar__slogan__nosearch {
  display: none;
}

html:not(.page--searching) .pageheader-searchbar__slogan__searching {
  display: none;
}