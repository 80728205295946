/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #999999;
}

input::placeholder {
  opacity: 1;
  color: #999999;
}

:disabled::-webkit-input-placeholder, [data-wh-form-disabled]::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}
:disabled input::placeholder, [data-wh-form-disabled] input::placeholder {
  opacity: 1;
  color: inherit;
}

input[type=text],
input[type=url],
input[type=email],
input[type=password],
input[type=number],
input[type=date],
input[type=time],
input[type=search],
textarea,
select:not(.wh-rtd__toolbarstyle),
.wh-form__dateinputgroup,
.wh-form__timeinputgroup,
.multiselect__panel__trigger,
.datetime__part {
  padding-left: 15px;
  height: 44px;
  border: 1px solid #979797;
  border-radius: 0px;
  background-color: #FFFFFF;
  color: #282828;
  font: 16px var(--rtd-text-fontfamily);
}
input[type=text]:focus,
input[type=url]:focus,
input[type=email]:focus,
input[type=password]:focus,
input[type=number]:focus,
input[type=date]:focus,
input[type=time]:focus,
input[type=search]:focus,
textarea:focus,
select:not(.wh-rtd__toolbarstyle):focus,
.wh-form__dateinputgroup:focus,
.wh-form__timeinputgroup:focus,
.multiselect__panel__trigger:focus,
.datetime__part:focus {
  border-color: #0067B4;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(40, 52, 119, 0.15) !important;
}
input[type=text]:disabled, input[type=text][data-wh-form-disabled],
input[type=url]:disabled,
input[type=url][data-wh-form-disabled],
input[type=email]:disabled,
input[type=email][data-wh-form-disabled],
input[type=password]:disabled,
input[type=password][data-wh-form-disabled],
input[type=number]:disabled,
input[type=number][data-wh-form-disabled],
input[type=date]:disabled,
input[type=date][data-wh-form-disabled],
input[type=time]:disabled,
input[type=time][data-wh-form-disabled],
input[type=search]:disabled,
input[type=search][data-wh-form-disabled],
textarea:disabled,
textarea[data-wh-form-disabled],
select:not(.wh-rtd__toolbarstyle):disabled,
select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-form__dateinputgroup:disabled,
.wh-form__dateinputgroup[data-wh-form-disabled],
.wh-form__timeinputgroup:disabled,
.wh-form__timeinputgroup[data-wh-form-disabled],
.multiselect__panel__trigger:disabled,
.multiselect__panel__trigger[data-wh-form-disabled],
.datetime__part:disabled,
.datetime__part[data-wh-form-disabled] {
  color: #888888;
  border-color: #C5C5C5;
  background-color: rgba(255, 255, 255, 0.6);
  box-shadow: none;
}

textarea {
  flex: 1 0 0px;
  padding-right: 15px;
}