body {
  --ttcta-item-font: var(--rtd-h2-font);
}
@media (max-width: 550px) {
  body {
    --ttcta-item-font: bold 23px/27px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 375px) {
  body {
    --ttcta-item-font: bold 22px/27px var(--rtd-heading-fontfamily);
  }
}

html.page--tt2-home h2 {
  text-transform: uppercase;
}

@media (max-width: 600px) {
  html.page--tt2-home .pageheader-bottombar.pageheader-searchbar {
    display: none !important;
  }
  .widget-ttcta {
    --header-to-content-margin: 15px;
  }
  .widget-ttcta + * {
    margin-top: var(--cblblock-items-spacing);
  }
}
@media (min-width: 601px) {
  html.page--tt2-home .page-contentstart {
    --header-to-content-margin: 37px;
    grid-column: var(--pagegrid-col-fullwidth);
    background: #F5F5F5;
    height: var(--header-to-content-margin);
  }
  .page--tt2-home .widget-ttcta {
    --header-to-content-margin: 0; /* stick to header */
  }
  .widget-ttcta + * {
    margin-top: 44px;
  }
}
.widget-ttcta {
  --rtd-spacing-paragraph: 10px;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.widget-ttcta__content {
  grid-column: var(--pagegrid-col-verywide);
  background: var(--currenttheme-background);
}

/* Tinted image */
.widget-ttcta__meta {
  /*
  background: linear-gradient(to right, var(--currenttheme-background), var(--currenttheme-background))
            , var(--bgimage);
  background-blend-mode: multiply, normal;
  background-size: cover, cover;
  background-position: var(--bgimage-position);
  */
  background: var(--bgimage);
  background-size: cover;
  background-position: var(--bgimage-position);
}

.widget-ttcta__meta__overlay {
  background: var(--currenttheme-background);
  mix-blend-mode: multiply;
  /*
  background: linear-gradient(to right, var(--currenttheme-background), var(--currenttheme-background));
  background-blend-mode: multiply;
  background-size: cover;
  */
}

.widget-ttcta__meta__text {
  position: relative;
  color: var(--currenttheme-heading-color, #DDDDDD);
  padding: var(--widget-ttcta-textpanel-pad-top) var(--widget-ttcta-textpanel-pad-h) var(--widget-ttcta-textpanel-pad-bottom) var(--widget-ttcta-textpanel-pad-h);
  --rtd-heading-color: var(--currenttheme-heading-color);
  --rtd-text-color: var(--currenttheme-text-color);
  --rtd-link-color: var(--currenttheme-link-color);
  /*
  Design had:
  - 14px/24px for desktop
  - 15px/20px for mobile
  - Using --widget-text-small which fixes on font-size 15px (but 20px or 24px line-height)
  */
  --rtd-text-font: var(--widget-text-small);
}

.widget-ttcta__meta__text a {
  text-decoration: underline;
  text-underline-offset: 3px;
}

.widget-ttcta__meta__text h2 {
  font: bold 34px/37px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  text-wrap: balance;
}

.widget--ttcta__items {
  display: flex;
  flex-direction: column;
  padding: var(--widget-ttcta-itemspanel-pad-top) var(--widget-ttcta-itemspanel-pad-h) var(--widget-ttcta-itemspanel-pad-bottom) var(--widget-ttcta-itemspanel-pad-h);
}

.widget--ttcta__items a {
  display: flex;
  align-items: baseline;
  min-height: 48px;
  color: #FFFFFF;
  font: var(--ttcta-item-font);
  background-color: rgba(0, 0, 0, 0.23);
  padding: 11px 15px 11px 15px;
  text-decoration: none;
}

.widget--ttcta__items a::before {
  content: var(--fa-arrow-right);
  font: var(--fontawesome-solid);
  font-size: 20px;
  margin-right: 15px;
  position: relative;
  top: -1px;
}

.widget--ttcta__items a:hover .ttcta-item-title {
  text-decoration: underline;
}

.widget--ttcta__items a + a {
  margin-top: 13px;
}

@media (max-width: 767px) {
  .widget-ttcta__content {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: min-content min-content min-content;
    --widget-ttcta-textpanel-pad-h: 15px;
    --widget-ttcta-textpanel-pad-top: 15px;
    --widget-ttcta-textpanel-pad-bottom: 15px;
    --widget-ttcta-itemspanel-pad-h: 15px;
    --widget-ttcta-itemspanel-pad-top: 15px;
    --widget-ttcta-itemspanel-pad-bottom: 15px;
  }
  .widget-ttcta__imagewrapper {
    grid-column: 1;
    grid-row: 1/span 2;
  }
  .widget-ttcta__image {
    width: 100%;
    aspect-ratio: 380/300;
    object-fit: cover;
    min-height: 100%; /* stretch if the text (+it's margin) makes the space larger than the image at it's correct aspect-ratio would be */
  }
  .widget-ttcta.widgetheight--shrinktofit .widget-ttcta__imagewrapper {
    contain: size;
  }
  .widget-ttcta.widgetheight--shrinktofit .widget-ttcta__image {
    height: 100%;
  }
  .widget-ttcta__meta__overlay,
  .widget-ttcta__meta__text {
    grid-column: 1;
    grid-row: 2;
    margin-top: 60px; /* ensure there is a little space showing part of the image even if the text is long */
  }
  .widget--ttcta__items {
    grid-column: 1;
    grid-row: 3;
  }
}
@media (min-width: 768px) {
  .widget-ttcta::before {
    content: "";
    grid-column: var(--pagegrid-col-fullwidth);
    grid-row: 1;
    background: #F5F5F5;
    height: 50%;
  }
  .widget-ttcta__content {
    grid-row: 1;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
    min-height: 225px;
    --widget-ttcta-textpanel-pad-h: 30px;
    --widget-ttcta-textpanel-pad-top: 25px;
    --widget-ttcta-textpanel-pad-bottom: 30px;
    --widget-ttcta-itemspanel-pad-h: 30px;
    --widget-ttcta-itemspanel-pad-top: min(3vw, 64px);
    --widget-ttcta-itemspanel-pad-bottom: min(3vw, 64px);
  }
  .widget-ttcta__imagewrapper {
    grid-column: 1;
    grid-row: 1/span 2;
  }
  .widget-ttcta.widgetheight--shrinktofit .widget-ttcta__imagewrapper {
    contain: size;
  }
  .widget-ttcta.widgetheight--shrinktofit .widget-ttcta__image {
    height: 100%;
  }
  .widget-ttcta__image {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  .widget-ttcta__meta__overlay,
  .widget-ttcta__meta__text {
    grid-column: 1;
    grid-row: 2;
  }
  .widget--ttcta__items {
    grid-column: 2;
    grid-row: 1/span 2;
    display: flex;
    flex-direction: column;
    align-items: start; /* shrinkwrap to the left */
    justify-content: center; /* vertical align content */
  }
}