/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.page__body {
  display: flex;
  flex-direction: column;
}

.page__body > .page__contentarea {
  flex: 1 0 auto;
}

/* Make sure the .page-contentstart element
   doesn't stretch and add extra whitespace. */
.page-contentstart {
  line-height: 0;
  height: 0;
  overflow: hidden;
  padding: 0;
  min-height: 0;
}

.page-contentstart,
.deeplinks-wrapper {
  grid-row: 1;
}

.page__body__header {
  grid-row: 2;
}

.page-contentstart.page-contentstart + *,
.deeplinks {
  margin-top: var(--header-to-content-margin);
}

/* Whitespace between normal/RTD page content and the footer.
   This "balloon" can expand if needed to push the footer
   to the bottom of the viewport. (in case the viewport is
   larger than the content of the page) */
.page__contentarea .page__balloon {
  margin-top: 0 !important; /* override selectors such as ".widget + *" setting a margin */
  height: var(--whitespace-footer-content);
  grid-column: var(--pagegrid-col-fullwidth);
}

/* Whitespace between custom page content and the footer
   FIXME: do we still use this? */
body > .page__balloon {
  grid-column: var(--pagegrid-col-fullwidth);
  flex: 1 0 auto;
  height: var(--whitespace-footer-content);
}

.page__body > .page__footer {
  flex: none;
}

.page__contentarea,
.wh-form--webtoolform,
.widget-accordion__item__body__content,
html.wh-widgetpreview body {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
  grid-auto-rows: max-content; /* when we get a height or flex-grow don't let it stretch our rows */
}

.page__body--disablegridlayout {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.page__body--disablegridlayout > .page__contentarea {
  display: block;
  grid-column: var(--pagegrid-col-default);
}

.pageheader__title {
  grid-column: var(--pagegrid-col-default);
}

@media (max-width: 1023px) {
  /* On mobile makes the default wide use the full viewport */
  .page__contentarea--rtddoc > *,
  .page__contentarea--formwebtool > .pageheader__title,
  .page__contentarea--formwebtool > *,
  .widget-accordion__item__body__content > *,
  html.wh-widgetpreview body > * {
    --pagegrid-col-default: var(--pagegrid-col-verywide);
    --pagegrid-col-default-max8col: var(--pagegrid-col-verywide);
    --pagegrid-col-default-left: var(--pagegrid-col-verywide-left);
    --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .page__contentarea--rtddoc > *,
  .page__contentarea--formwebtool > .pageheader__title,
  .page__contentarea--formwebtool > *,
  .widget-accordion__item__body__content > *,
  html.wh-widgetpreview body > * {
    grid-column: var(--pagegrid-col-default);
  }
}
/* Support grid layout for formwebtool documents

 - Don't use a wrapper, becauce it will override the specificy and therefore overriden the layout of all widgets!
 - However in the future we may need to change the widgets CSS (to have a higher specificy) so we can support other .wh-form usage too

 - In the very near futurefuture we may be able to use :where(.page__contentarea > .wh-form), because the selectors in :where have 0 specificy

*/
@media (max-width: 1023px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-verywide);
  }
}
@media (min-width: 1024px) {
  .wh-form > *,
  .wh-form__page > *,
  .wh-form__richtext > * {
    grid-column: var(--pagegrid-col-default);
  }
}

/* NOTE: We assume all .wh-form's directly embedded into .page__contentarea
         to follow the main page content layout.
         (This will apply to "Form" pages and the Publisher "Open form test") */
.page__contentarea > .wh-form,
.page__contentarea > .wh-form .wh-form__page,
.page__contentarea > .wh-form .wh-form__fieldgroup--richtext {
  grid-column: var(--pagegrid-col-fullwidth);
}

.page__contentarea > .wh-form .wh-form__richtext {
  width: 100%;
}

.page__contentarea > .wh-form,
.page__contentarea > .wh-form .wh-form__page--visible,
.page__contentarea > .wh-form .wh-form__richtext {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-flow: row;
}

.wh-form > a.wh-anchor,
.wh-form__page > a.wh-anchor,
.wh-form__richtext > a.wh-anchor {
  position: relative;
}

.gridcontent-wide {
  --pagegrid-col-default: var(--pagegrid-col-verywide);
  --pagegrid-col-default-left: var(--pagegrid-col-verywide-left);
  --pagegrid-col-default-right: var(--pagegrid-col-verywide-right);
  --pagegrid-col-narrow: var(--pagegrid-col-verywide);
  --pagegrid-col-verynarrow: var(--pagegrid-col-verywide);
}