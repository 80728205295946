html.filter_toggle_fullscreen.filteredoverview--showfilters body,
html.filter_toggle_fullscreen.filteredoverview--showfilters body {
  background-color: var(--color-lightgrey) !important;
}
html.filter_toggle_fullscreen.filteredoverview--showfilters .page__contentarea, html.filter_toggle_fullscreen.filteredoverview--showfilters .footer,
html.filter_toggle_fullscreen.filteredoverview--showfilters .page__contentarea,
html.filter_toggle_fullscreen.filteredoverview--showfilters .footer {
  display: none !important;
}

.header-filterbar {
  flex-wrap: wrap;
}

.header-filterbar .filteredoverview__form.filteredoverview__form.filteredoverview__form {
  width: 100%;
  background-color: transparent;
  padding: 0;
}