/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
/*
NOTES: even though the background for this widget is fullwidth,
       the content within isn't. So we don't need to include "@include overlap-deeplinks;"
*/
.widget--persons {
  background-color: var(--color-lightgrey);
  padding-top: 32px;
  padding-bottom: 32px;
}

.widget--persons > h3 {
  grid-column: var(--pagegrid-col-narrow);
  margin-bottom: 25px;
}

.widget--persons__item + .widget--persons__item {
  margin-top: 25px;
}

.widget--person__photo,
.widget--person__photoplaceholder {
  display: block;
}

.person__spacer {
  flex: 0 1 5vw;
  min-width: 12px;
  max-width: 30px;
}

.widget--person__photoplaceholder {
  background-color: #E8E8E8;
}

.widget--person__photoplaceholder::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.person__meta {
  grid-column: 8/span 6;
}

.person__role {
  color: var(--color-red);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

.person__fullname {
  font-size: 18px;
  line-height: 16px;
  color: #000000;
  font-weight: bold;
}

.person__fullname + .person__workingarea {
  margin-top: 4px;
}

.person__meta__contact {
  margin-top: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
}

.person__workingarea {
  font-size: 14px;
}

a.person__email,
a.person__phone {
  color: inherit;
  text-decoration: none;
}

.person__workingarea {
  font-style: italic;
}

.widget--persons__item {
  display: flex;
}

.widget--person__photo,
.widget--person__photoplaceholder {
  flex: none;
  display: block;
  width: 115px;
  height: 115px;
  max-width: 19vw;
  max-height: 19vw;
}

.widget--persons {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-column: var(--pagegrid-col-fullwidth);
}

.widget--persons__item {
  grid-column: var(--pagegrid-col-narrow);
}

.widget-richcontent .widget-persons__title {
  grid-column: var(--pagegrid-col-verywide);
}
.widget-richcontent .widget--persons__item {
  grid-column: var(--pagegrid-col-verywide);
}

/*
@media (min-width: $minwidth-centercontent)
{
  .widget--persons
  {
    display: grid;
    @include layout--continue-grid-within;
    grid-column: var(--pagegrid-col-fullwidth);
  }
  // use grid per item so we can support IE without auto placement
  .widget--persons__item
  {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }

    .widget--person__photo
  , .widget--person__photoplaceholder
  {
    width: calc(100% - 30px);
    height: auto;

    // width: 115px;
    // height: 115px;

    margin-right: 30px; // FIXME: gap size

    grid-column: 7;
  }
}
*/