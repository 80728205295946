.page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages {
  --gridimage-aspectratio: 1 / 1 !important;
  --component-spctile-dateindicator-width: 70px;
  --component-spctile-image-listview-width: 146px;
  --component-spctile-image-border-radius: 50%;
}
@media (min-width: 450px) {
  .page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-tile {
    display: grid;
    grid-template-columns: var(--component-spctile-dateindicator-width) var(--component-spctile-image-listview-width) 30px 1fr;
    grid-template-rows: min-content min-content 1fr;
  }
}
@media (max-width: 449px) {
  .page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-tile {
    --component-spctile-padding-h: 0;
  }
  .page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-tile__top + .spc-tile__meta {
    margin-top: 15px;
  }
}
.page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-eventtile__date {
  flex: none;
  width: 50px;
  height: var(--component-spctile-image-listview-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  grid-column: 1;
  grid-row: 1/span 3;
  color: var(--color-theme);
  /*
  leading-trim: both;
  text-edge: cap alphabetic;
  */
}
.page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-tile__top {
  grid-column: 2;
  grid-row: 1/span 3;
}
.page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-tile__meta {
  grid-column: 4;
  grid-row: 1;
}
.page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-tile__description {
  margin-top: 5px;
  grid-column: 4;
  grid-row: 2;
}
.page--eventsoverview .folderwithfilters__items--listview.folderwithfilters__items--withimages .spc-eventtile__specsheet {
  margin-top: 15px;
  grid-column: 4;
  grid-row: 3;
}

.spc-tile__tagslist {
  margin-bottom: 10px;
}

.spc-eventtile__date__day {
  font: 48px/37px var(--rtd-heading-fontfamily);
}

.spc-eventtile__date__month {
  font: 18px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.spc-eventtile__specsheet {
  color: #333333;
  font: 16px var(--rtd-text-fontfamily);
}

.spc-eventtile__specsheet > * {
  display: flex;
  align-items: center;
}

.spc-eventtile__specsheet > * + * {
  margin-top: 2px;
}

@media (max-width: 1200px) {
  .spc-tile {
    --component-spctile-dateindicator-width: 0px;
  }
  .spc-eventtile__date.spc-eventtile__date {
    display: none !important;
  }
}