/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.header-top .header-top-background,
.header-menubar,
.header-menubar-background {
  z-index: 11;
}

.header-menubar,
.header-menubar-background {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.header-top-background,
.header-menubar-background {
  grid-column: var(--pageheader-background-col);
  background-color: #FFFFFF;
}

.header-menubar__content {
  height: 65px;
}

html {
  --siteheader-top-height: 90px;
  --siteheader-menubar-height: 65px;
  --siteheader-scroll-whitespace: 30px;
}

.wh-anchor,
.wh-rtd__anchor,
.deeplinktarget {
  content: "";
  width: 0;
  position: absolute;
  display: inline-block;
  margin-top: -95px;
  pointer-events: none;
}

#section-navigation {
  scroll-margin-top: calc(var(--siteheader-menubar-height) + var(--siteheader-scroll-whitespace));
}

@media (max-width: 799px) {
  html {
    --siteheader-top-height: 70px;
    --siteheader-menubar-height: 0px;
    --siteheader-scroll-whitespace: 30px;
  }
  #section-navigation {
    scroll-margin-top: calc(var(--siteheader-top-height) + var(--siteheader-scroll-whitespace));
  }
  .header-top,
  .header-top-background {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  .header-top__content {
    height: var(70px);
  }
  /* whe don't hide the main .header-menubar
     so it'll reserve it's row.
     (to prevent other elements popping up there on mobile) */
  .header-menubar__content {
    display: none;
  }
  .wh-anchor,
  .wh-rtd__anchor,
  .deeplinktarget {
    content: "";
    width: 0;
    position: absolute;
    display: inline-block;
    margin-top: -100px;
    pointer-events: none;
  }
}