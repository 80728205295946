.page--eventsoverview .page-header__meta,
.page--eventsoverview .pagetabs,
.eventsoverview-searchresults {
  grid-column: var(--pagegrid-col-wide);
}

@media (max-width: 850px) {
  .page--eventsoverview .page-header__meta,
  .page--eventsoverview .pagetabs,
  .eventsoverview-searchresults {
    grid-column: var(--pagegrid-col-verywide);
  }
}
.page--eventsoverview .pagetabs {
  padding: 24px 0;
  border-bottom: 1px solid #C8C8C8;
  margin-bottom: 10px;
}

.page--eventsoverview .pagetabs {
  display: block;
}

.biglistitem {
  grid-column: var(--pagegrid-col-default);
}

a.biglistitem__wrappinglink {
  display: block;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-left: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

a.biglistitem__wrappinglink:hover {
  background-color: #F5F5F5;
}

a.biglistitem__wrappinglink:hover .biglistitem__title {
  text-decoration: underline;
}

.biglistitem__header {
  font: var(--rtd-h3-font);
  text-transform: uppercase;
}

.biglistitem__date {
  color: #000000;
}

.biglistitem__title {
  color: var(--color-theme);
  margin: 0;
}

.biglistitem__description {
  color: var(--rtd-text-color);
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
@media (max-width: 700px) {
  .biglistitem__description {
    -webkit-line-clamp: 5;
  }
}

.biglistitem__content {
  display: flex;
}

.biglistitem__imagecol {
  margin-right: 30px;
}

.biglistitem__image {
  width: 115px;
  height: 115px;
}

/********************************************************
**
**  Desktop search results layout
*/
.page--eventsoverview {
  /*
  .filteredoverview__feedbackandviewbar / * hide X results and list of tags * /
  {
    display: none;
  }
  */
  /* mobile and small tablets */
}
@media (max-width: 799px) {
  .page--eventsoverview .eventsoverview-type-label {
    display: none;
  }
  .page--eventsoverview .eventsoverview-month {
    display: none;
  }
}
.page--eventsoverview .acoifilterpage-filters__topbar .eventsoverview-month {
  margin-left: auto;
}
.page--eventsoverview .biglistitem__wrappinglink {
  text-decoration: none;
  border-top: var(--listitem-border);
}
.page--eventsoverview .biglistitems .biglistitem__content {
  padding-top: calc(var(--component-summary-padding-v) - 3px);
  padding-bottom: var(--component-summary-padding-v);
}
.page--eventsoverview .eventsoverview-biglistitems a:hover {
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: var(--listitem-border);
  margin-bottom: -2px;
}
.page--eventsoverview .eventsoverview-biglistitems a:hover .biglistitem__content {
  background-color: var(--color-lightgrey);
  padding-left: 20px;
  padding-right: 20px;
}
.page--eventsoverview .eventsoverview-biglistitems a:hover .biglistitem__title {
  text-decoration: underline;
}
.page--eventsoverview .biglistitem__description {
  grid-column: 1/3;
}
@media (max-width: 767px) {
  .page--eventsoverview * + .biglistitem__category {
    margin-top: 10px;
  }
}
.page--eventsoverview .eventsoverview-biglistitems {
  border-bottom: var(--listitem-border);
}