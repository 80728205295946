.page-header__navpath {
  display: flex;
  align-items: center;
}

a.navpath__item {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  padding-left: 4px;
  padding-right: 4px;
}

a.navpath__item:first-child {
  padding-left: 0;
}

a.navpath__item:hover {
  text-decoration: underline;
}

.navpath__items a,
.navpath__items span {
  padding-left: 4px;
  padding-right: 4px;
}

.navpath__seperator {
  flex: none;
}
.navpath__seperator::before {
  content: "/";
  /*
  @include iconfont;
  @include icon-right;
  font-size: 12px;
  */
}