@import "../../../shared/web/fonts/fontawesome/css/all.css";
@import "../../../shared/web/fonts/fontawesome/fontawesome-cssvars.css";
/*
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,500,600&display=swap");
*/

@import "../../web/fonts/fonts.css";


html
{
  font-family: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  -webkit-text-size-adjust: 100%;

  overflow-y: scroll;

  color: var(--rtd-text-color)
}
.wh-rtd-embeddedobject
{
  font-size: 16px; /* otherwise Webhare will override our html font-size */
  line-height: 24px;
}



html
{
  --whitespace-header-content: 35px;

  --background-blue-gradient: linear-gradient(90deg, #14143C 0.09%, #28286C 100%);

  --rtd-text-color: #666666;
  --rtd-link-color: #D82F37;
}


/*
Notes on apple-data-detectors:
- Must use !important to override properties
- Can also create a anchor <a> around phone numbers: <a href="tel:" />
- The links are automatically added in the Safari app, but a webview within another app may not show them
*/
a[x-apple-data-detectors]
{
  color: #D82F37 !important;
}


body
{
  background-color: #FFFFFF; /* required for WCAG compliance */
}

  h1
, h2
, h3
, h4
, .wh-form__grouptitle
{
  font-family: var(--rtd-heading-fontfamily);
  color: var(--rtd-heading-color);

  /* prevent the antialiasing making the font even more bold
     (unfortunately the Gilroy font only has a Light and ExtraBold version */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1
{
  font: var(--rtd-h1-font);
  margin: 0;
}

/* style_as_h2 is used for widgets.
   We cannot use h2 elements because they are not meant to signify
   a new header for search-engines and deeplinks.

   We use a class instead of mixin because:
   - the styling would also work in the richdocument editor
   - we don't need fine grained control over whether to use this style depending on different layout modes
*/
  h2
, .widget__title
{
  font: var(--rtd-h2-font);
  margin: 0;
}
.widget__title
{
  text-transform: uppercase;
}

/* Widgets with their own color background get a larger widget__title */
  .widget-cta__title
, .widget-ctablocks__title
, .widget-news__title
, .widget-projects__title
{
  font: var(--widget-withbg-title-font);
  text-transform: uppercase;
}

/* same size as paragraphs, but bold and heading font */
  h3
, .wh-form__grouptitle
, .style_as_h3
{
  font: var(--rtd-h3-font);
  text-transform: uppercase;
  margin: 0;
}

h4
{
  font: var(--rtd-h4-font);
}

  p.normal
, p.intro
, ol.ordered
, ul.unordered
{
  font: var(--rtd-text-font);
  color: var(--rtd-text-color);
}

p.intro
{
  font-weight: bold;
}

  p.normal a
, ul.unordered a
, ol.ordered a
{
  color: var(--rtd-link-color);
}




sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}



/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}



/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}

ul.unordered
{
  padding-left: 20px;
}

ol.ordered
{
  padding-left: 19px;
}

ul.unordered li
{
  padding-left: 2px;
}

ol.ordered li
{
  padding-left: 2px;
}

ul li::marker
{
  color: var(--rtd-list-ul-marker-color);
  font-size: 18px;
}

ol li::marker
{
  color: var(--rtd-list-ol-marker-color);
  font-size:   15px;
  font-weight: bold;
}




/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}



  .wh-video
, p.normal
, ul.unordered
, ol.ordered
{
  /* when changing this value also change $paragraph_space_between in config.scss */
  margin-top:    var(--rtd-spacing-paragraph);
  margin-bottom: 0;
}

  h2.heading2
, h3.heading3
, h4.heading4
, .widget
{
  margin-top: 30px;
}

.widget-downloadsandlinks
{
  margin-top: 25px; /* in plaats van 15 van var(--rtd-spacing-paragraph); */
}



/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}
  .wh-rtd > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}
