/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
/*

Button:
- link
- outline
- solid


Defining a button
    a.wh-form-button
    button.wh-form-button
    *.wh-form-button

Layout modifyers:
    .wh-form-button--outline - Overrides the default solid style of the button
    .wh-form-button--solid

Communicating intent (icons):
    .wh-form__button--backward
    .wh-form__button--forward

*/
.wh-form__button {
  border: 0 none;
  outline: none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font-family: var(--rtd-text-fontfamily);
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  height: 50px;
  padding: 0 20px;
  border-radius: 0;
  transition: background-color 0.3s;
  cursor: pointer;
}

.wh-form__button:not(:where(.wh-form__button--solid, .wh-form__button--next, .wh-form__button--submit)),
.wh-form__button--outline {
  background-color: transparent;
  border: 1px solid var(--button-outline-border-color);
  color: var(--button-outline-text-color);
}
.wh-form__button:not(:where(.wh-form__button--solid, .wh-form__button--next, .wh-form__button--submit)):hover,
.wh-form__button--outline:hover {
  background-color: var(--color-theme);
  color: #FFFFFF;
}
.wh-form__button:not(:where(.wh-form__button--solid, .wh-form__button--next, .wh-form__button--submit)) .wh-form__button--disabled,
.wh-form__button--outline .wh-form__button--disabled {
  border: 1px solid #AAAAAA;
  color: #666666;
  cursor: default;
}

.wh-form__buttongroup .wh-form__button {
  background-color: transparent;
  border: 1px solid var(--button-outline-border-color);
  color: var(--button-outline-text-color);
}
.wh-form__buttongroup .wh-form__button:hover {
  background-color: var(--button-color);
  color: #FFFFFF;
}
.wh-form__buttongroup .wh-form__button .wh-form__button--disabled {
  border: 1px solid #AAAAAA;
  color: #666666;
  cursor: default;
}

.wh-form__button--next,
.wh-form__button--submit {
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
}
.wh-form__button--next:hover,
.wh-form__button--submit:hover {
  background-color: var(--button-color-hover);
  color: #FFFFFF;
}
.wh-form__button--next .wh-form__button--disabled,
.wh-form__button--submit .wh-form__button--disabled {
  background-color: #AAAAAA;
  color: #666666;
  cursor: default;
}

.wh-form__button.wh-form__button--solid {
  background-color: var(--color-theme);
  color: #FFFFFF;
  border: 0;
}
.wh-form__button.wh-form__button--solid:hover {
  background-color: var(--color-theme-darker);
  color: #FFFFFF;
}
.wh-form__button.wh-form__button--solid .wh-form__button--disabled {
  background-color: #AAAAAA;
  color: #666666;
  cursor: default;
}

.wh-form .wh-form__button {
  height: 44px;
}

.wh-form__navbuttons .wh-form__button {
  height: 50px;
}

.wh-form__button--backward::before,
.wh-form__button--previous::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f060";
  font-size: 13px;
  padding-right: 15px;
  margin-right: auto;
}

/*
.wh-form__button--backward::before
{
  margin-right: 15px;
}
.wh-form .wh-form__button--previous::before
{
  margin-right: auto;//50px;
}
*/
.spc-textbutton .wh-form__button--backward::before {
  margin-right: 15px;
}

.wh-form__button--forward::after,
.wh-form__button--next::after,
.wh-form__button--submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-arrow-right);
  font-size: 13px;
  margin-left: 50px;
}

.spc-textbutton .wh-form__button--forward::after {
  margin-left: 15px;
}

.widget-cta .wh-form__button--forward::after {
  margin-left: 70px;
}

.wh-form__button--next,
.wh-form__button--submit {
  justify-content: space-between;
}

.widget--background-blue .wh-form__button.wh-form__button,
.widget--background-bluegradient .wh-form__button.wh-form__button,
.widget--background-red .wh-form__button.wh-form__button,
.widget--background-redgradient .wh-form__button.wh-form__button {
  background-color: transparent;
  border-color: #FFFFFF;
  color: #FFFFFF;
}

.widget--background-blue .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: var(--color-blue);
}

.widget--background-bluegradient .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: #14143C;
}

.widget--background-red .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: var(--color-red);
}

.widget--background-redgradient .wh-form__button.wh-form__button:hover {
  background-color: #FFFFFF;
  color: #7B3F3C;
}

form.wh-form--submitting .wh-form__button--submit {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.spc-textbutton {
  color: var(--color-theme);
  text-decoration: none;
}

.spc-textbutton:hover {
  color: var(--color-theme-darker);
  text-decoration: underline;
}