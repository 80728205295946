.searchresults {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.searchresults--teachingtoolkit {
  --pagegrid-col-verywide: var(--pagegrid-col-verynarrow);
  --resultslist-thumbnail-width: 120px;
}
.searchresults--teachingtoolkit .contentsummary__itemcount {
  margin-top: var(--header-to-content-margin);
  grid-column: var(--pagegrid-col-verynarrow);
}

.categoryblocks {
  margin-top: var(--header-to-content-margin);
  grid-column: var(--pagegrid-col-verywide);
  display: grid;
  column-gap: 30px;
  row-gap: 30px;
  --categoryblock-pad-h: 28px;
  --categoryblock-pad-v: 20px;
  --categoryblock-title-height: 90px;
  --toolkitblock-title-font: bold 34px/37px var(--rtd-heading-fontfamily);
  --toolkitblock-text-font: 16px/28px var(-rtd-text-fontfamily);
}
@media (min-width: 768px) {
  .categoryblocks {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  .categoryblocks .categoryblocks__item {
    min-height: 310px;
  }
}

@media (max-width: 550px) {
  .categoryblocks {
    --header-to-content-margin: 20px;
    --categoryblock-pad-h: 25px;
    --categoryblock-pad-v: 18px;
    --categoryblock-title-height: 67px;
    --toolkitblock-title-font: bold 22px/24px var(--rtd-heading-fontfamily);
    --toolkitblock-text-font: 14px/20px var(-rtd-text-fontfamily);
  }
}
.categoryblocks__category {
  pointer-events: none;
}

.categoryblocks__category a {
  pointer-events: auto;
}

.categoryblocks__item__linkbg {
  grid-column: 1/-1;
  grid-row: 1/-1;
  /*  background-color: rgba(0, 255, 255, 0.5);*/
}

.categoryblocks__info {
  background: var(--color-blue);
}

.categoryblocks__info .widget-links + * {
  margin-top: 30px;
}

.categoryblocks__info .categoryblocks__item__description {
  /*  padding: var(--categoryblock-pad-v) var(--categoryblock-pad-h) 17px var(--categoryblock-pad-h);*/
  padding: 0 var(--categoryblock-pad-h) 17px var(--categoryblock-pad-h);
}

.categoryblocks__category {
  display: block;
  /*  outline: 1px solid #F00;*/
  background: linear-gradient(to right, var(--color-red), var(--color-red)), var(--bgimage);
  background-blend-mode: multiply, normal;
  background-size: cover, cover;
  background-position: var(--bgimage-position);
  display: grid;
  grid-template-rows: min-content minmax(min-content, 1fr) 1px min-content;
  grid-template-columns: 100%;
}
@media (min-width: 800px) {
  .categoryblocks__category {
    grid-template-columns: 1fr 40px;
  }
}
@media (min-width: 1200px) {
  .categoryblocks__category {
    grid-template-columns: 1fr 145px;
  }
}

.categoryblocks__category.categoryblock__item--noimage {
  background: #3c0a0b;
}

.categoryblocks__item {
  color: #FFFFFF;
}

.categoryblocks__item__insetbg {
  grid-column: 1;
  grid-row: 2;
  background-color: var(--color-red); /* Red - #C72125 */
}

.categoryblocks__item h2 {
  grid-row: 1;
  grid-column: 1/span 2;
  display: flex;
  align-items: center;
  min-height: var(--categoryblock-title-height);
  padding: 12px var(--categoryblock-pad-h);
  color: #FFFFFF;
  font: var(--toolkitblock-title-font);
  text-transform: uppercase;
  text-wrap: balance;
}

.categoryblocks__item__textcontent {
  grid-row: 2;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  padding: var(--categoryblock-pad-v) var(--categoryblock-pad-h) 17px var(--categoryblock-pad-h);
}

.categoryblocks__item__description {
  --rtd-text-color: #FFFFFF;
  --rtd-text-font: var(--toolkitblock-text-font);
  --rtd-link-color: #FFFFFF;
  margin-bottom: 20px;
}

.categoryblocks__item hr {
  border: 0;
  border-top: 1px solid #FFFFFF;
  margin: auto 0 17px 0;
  width: 100%;
}

.categoryblocks__item__link {
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
  font: bold 14px var(--rtd-heading-fontfamily);
  letter-spacing: 0.5px;
}

.categoryblocks__item__link::before {
  display: inline-block;
  text-decoration: none;
  content: var(--fa-arrow-right);
  font: var(--fontawesome-regular);
  margin-right: 12px;
}

.categoryblocks__item__link:hover {
  text-decoration: underline;
}