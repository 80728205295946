.folderwithfilters__items--listview .consilio-match {
  font-weight: bold;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
}

.page--folderwithfilters--list .page__contentarea > .folderwithfilters__rtdcontent {
  display: grid;
  grid-column: var(--pagegrid-col-fullwidth);
  grid-template-columns: var(--pagegrid-template-columns);
}
.page--folderwithfilters--list .folderwithfilters__rtdcontent > * {
  grid-column: var(--pagegrid-col-default);
}

.folderwithfilters__items--listview {
  grid-column: var(--pagegrid-col-verywide);
  display: flex;
  flex-direction: column;
  row-gap: var(--component-spctile-spacing);
}