html
{
  --cblblock-background:     #c72125; /* var(--color-red) */
  --cblblock-padding-h:      20px;
  --cblblock-padding-top:    20px;
  --cblblock-padding-bottom: 20px;
  --cblblock-items-spacing:  30px;
}

.widget-cblblocks
{
  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);

  z-index: var(--zindex-overlap-deeplinks);
}

/* Shrink spacing a little for tablet layout */
@media (min-width: 600px) and (max-width: 1024px)
{
  .widget-cblblocks
  {
    --cblblock-items-spacing:  20px;
  }
}


.widget-cblblocks__item.themecolor--blue
{
  --cblblock-background: #0067B4;
}

.widget-cblblocks__item.themecolor--purple
{
  --cblblock-background: #74237D;
}


.widget-cblblocks__items
{
  grid-column: var(--pagegrid-col-verywide);

  /*
  // create space for the box-shadow of children
  margin: -4px;
  padding: 4px;
  */
}


.widget-cblblocks__item-top
{
  /* Cut off animated/scaling image (on hovering) */
/*  position: relative;*/
  overflow: hidden;
}

.widget-cblblocks__item-image
{
  width: 100%;
  height: 100%;
  object-fit: cover;

  transition: transform 0.15s ease-in-out
            , opacity   0.25s;
}


.widget-cblblocks__item-overlay
{
  /* "coloredoverlay" effect */
  background: var(--cblblock-background);
  mix-blend-mode: multiply;
}



.widget-cblblocks__item
{
  background: var(--cblblock-background);
}

.widget-cblblocks__item:hover
{
  box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}

.widget-cblblocks__item:hover .widget-cblblocks__item-image
{
  transform: scale(1.1);
}

.widget-cblblocks__item:hover .widget-cblblocks__item-title .text
{
  text-decoration: underline;
}



.widget-cblblocks__item-title
{
  color: #FFFFFF;
  font: var(--cblblocks-item-title-font);
  text-decoration: none;

  display: flex;
  align-items: baseline;
}



.widget-cblblocks__item
{
  min-height: 140px;

  display: grid;
  grid-template-columns: 90px 1fr;
  grid-template-rows: min-content 1fr;

  text-decoration: none;
}

@media (max-width: 600px)
{
  .widget-cblblocks__item
  {
    grid-template-columns: 0px 1fr;
    min-height: 0;
  }

    .widget-cblblocks__item-top
  , .widget-cblblocks__item-overlay
  {
    display: none; /* also prevents the image being loaded without it being needed */
  }
}



.widget-cblblocks__item-top
{
  display: block;

  grid-column: 1;
  grid-row: 1 / span 2;
}

.widget-cblblocks__item-overlay
{
  grid-column: 1;
  grid-row: 1 / span 2;
}

.widget-cblblocks__item-title
{
  grid-column: 1;
  grid-row: 1 / span 2;
}

.widget-cblblocks__item-description
{
  grid-column: 2;
  grid-row: 2;
}



.widget-cblblocks__item-title
{
/*  padding: 13px 21px;*/
/*  padding: var(--cblblock-padding-v) var(--cblblock-padding-h);*/

  padding-left: var(--cblblock-padding-h);
  padding-right: var(--cblblock-padding-h);

  padding-top: var(--cblblock-padding-top);
  padding-bottom: var(--cblblock-padding-bottom);

  align-self: center;

  display: flex;
  align-content: center;
}

.widget-cblblocks__item-description
{
  padding-left: var(--cblblock-padding-h);
  padding-right: var(--cblblock-padding-h);

  --rtd-link-color: #FFFFFF;
  --rtd-text-color: #FFFFFF;
  --rtd-text-font:  var(--widget-text-small);
}

.widget-cblblocks__item:hover
{
  box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
}


@media (max-width: 767px)
{
  html
  {
    --cblblock-padding-top:    15px;
    --cblblock-padding-bottom:  7px;
    --cblblock-items-spacing:  15px;
  }

  .widget-cblblocks__item + .widget-cblblocks__item
  {
    margin-top: var(--cblblock-items-spacing);
  }

  .widget-cblblocks__item-title
  {
    font: bold 23px/28px var(--rtd-heading-fontfamily);
  }

  .widget-cblblocks__item-title::before
  {
    margin-right: 15px;

    position: relative;
    top: -1px;

    color: #FFFFFF;

    font: var(--fontawesome-solid);
    font-size: 20px;
    content: var(--fa-arrow-right);
  }

  .widget-cblblocks__item-description
  {
    padding-bottom: 15px;
  }
}


@media (min-width: 768px)
{
  .widget-cblblocks__items
  {
    display: grid;

    /* grid-template-columns: repeat( auto-fit, minmax(280px, 1fr) ); */

    /* 300px and gap of 30px makes it switch to single-column below 768px */
    grid-template-columns: repeat(auto-fill, minmax( max( calc(100% / 3 - 30px * 2) /* max three rows */
                                                        , 300px                     /* minimum size of block */
                                                        )
                                                   , 1fr));
    gap: var(--cblblock-items-spacing);
  }

  .widget-cblblocks__item-title
  {
    font: bold 24px/28px var(--rtd-heading-fontfamily);

    justify-content: space-between;
  }

  .widget-cblblocks__item-title::after
  {
    align-self: stretch;

    color: #FFFFFF;

    display: block;
    content: var(--fa-arrow-right);
    font: var(--fontawesome-solid);
    font-size: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .widget-cblblocks__item-description
  {
    padding-bottom: 20px;
  }


  .widget-cblblocks__item:hover::after
  {
    background-color: var(--color-theme-darker);
  }
}
