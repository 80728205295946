.page__body__stickycontent {
  display: none;
  position: sticky;
  contain: size;
  top: 65px; /* FIXME: need height of menubar + 1 .. but why is this relative to <body> instead of .page__body ?? */
  z-index: var(--zindex-overlap-contentarea);
}

html.navpath--sticky .page__body__stickycontent {
  display: block;
}

.page__body__stickycontent__content {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__body__stickycontent .page-header__navpath {
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1;
}

/*
  .ph--navpath-seperate / * textual header, ... * /
, .page-header--basic
, .page-header--withimage
, .page-header--withslideshow
, html.ph--basic .page__body__stickycontent
, html.ph--withimage .page__body__stickycontent
, html.ph--withslideshow .page__body__stickycontent
{
*/
.page-header__navpath {
  font: 14px var(--rtd-text-fontfamily);
}

.navpath__seperator {
  margin-left: 7px;
  margin-right: 7px;
}

/*
}
*/
.page-stickycontent__background {
  grid-row: 1/2;
}

html.ph--withimage .page-stickycontent__background {
  grid-column: 1/-1;
}

html.ph--navpath-seperate .page__body__stickycontent__content {
  background: #FFFFFF;
}
html.ph--navpath-seperate .page-stickycontent__background {
  grid-column: var(--pagegrid-col-verywide);
  background-color: #FFFFFF;
  border-bottom: 1px solid #D3D3D3;
}

html.ph--overlaybar .page-stickycontent__background,
html.ph--withslideshow .page-stickycontent__background {
  grid-column: 1/-1;
}
html.ph--overlaybar .page-stickycontent__background,
html.ph--withslideshow .page-stickycontent__background {
  background: rgba(242, 239, 237, 0);
  border-bottom: 1px solid #D3D3D3;
  transition: background 1s;
}
html.ph--overlaybar.navpath--sticky .page-stickycontent__background,
html.ph--withslideshow.navpath--sticky .page-stickycontent__background {
  background: rgba(242, 239, 237, 0.9) !important; /* this is #F2EFED */
  backdrop-filter: blur(3px);
}
html.ph--overlaybar .page-header__navpath,
html.ph--withslideshow .page-header__navpath {
  position: relative;
}

html.ph--sidebyside .page-stickycontent__background {
  border: none;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0);
  transition: box-shadow 0.5s;
}
html.ph--sidebyside.navpath--sticky .page-stickycontent__background {
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
}
html.ph--sidebyside .page-stickycontent__background::before {
  grid-row: 1/span 2;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.92) 100%), rgba(143, 63, 152, 0.92);
}
html.ph--sidebyside .page-header__navpath {
  border: none;
  color: #FFFFFF;
}