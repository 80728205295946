/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
Used in inGenious, JZOJP, TUE

If Internet Explorer 11 wouldn't need to be supported we could:
- use grid + grid-gap + autoplacement (IE doesn't support grid gap, but autoplacement could be fakes using nth-child and setting a column+row or using a polyfill script)
- use flex and CSS vars for columns (can we use them in media queries?)


NOTES:
- We use width instead of flex-basis because IE doesn't support calc() in flex-basis
*/
/*
$projectsgrid-2col:  768px;
$projectsgrid-3col: 1000px;
$projectsgrid-4col: 1280px;

*/
/*
.widget-projects__cta
{
  order: 10;
  margin-left: $grid_spacing;
}
.widget-projects__items > :nth-child(2)
{
  margin-left: 0;
}

@media (max-width: 1280px)
{
  .widget-projects__items > :nth-child(n+4)
  {
    display: none;
  }
}

@media (max-width: 1000px)
{
  .widget-projects__items > :nth-child(n+3)
  {
    display: none;
  }
}
*/
.widget-projects__title {
  grid-column: var(--pagegrid-col-verywide);
  font: var(--widget-withbg-title-font);
  margin-bottom: 22px;
}

.widget-projects {
  grid-column: var(--pagegrid-col-fullwidth);
  z-index: 10;
  background-color: var(--color-lightgrey);
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 0;
  padding-right: 0;
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  max-width: 100%;
}

.widget-projects__items {
  display: flex;
  grid-column: var(--pagegrid-col-verywide);
}

@media (max-width: 767px) {
  .widget-projects__items {
    display: block;
  }
  .widget-projects__items > * > * + * {
    margin-top: 30px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .widget-projects__items {
    overflow: hidden;
  }
  .widget-projects__items > * {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: -30px;
  }
  .widget-projects__items > * > * {
    width: calc((100% - 30px * 2) / 2);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
@media (min-width: 1000px) and (max-width: 1279px) {
  .widget-projects__items {
    overflow: hidden;
  }
  .widget-projects__items > * {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: -30px;
  }
  .widget-projects__items > * > * {
    width: calc((100% - 30px * 3) / 3);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
@media (min-width: 1280px) {
  .widget-projects__items {
    overflow: hidden;
  }
  .widget-projects__items > * {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: -30px;
  }
  .widget-projects__items > * > * {
    width: calc((100% - 30px * 4) / 4);
    margin-left: 30px;
    margin-bottom: 30px;
  }
}
.widget-projects__cta {
  display: flex;
  flex-direction: column;
  background-color: var(--color-theme);
  padding-top: 20px;
}

.widget-projects__cta__title {
  flex: none;
  font: bold 18px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
}

.widget-projects__cta__description {
  margin-top: 15px;
  flex: none;
  margin-bottom: auto;
}

.widget-projects__cta__title,
.widget-projects__cta__description.rtdcontent > *,
.widget-projects__cta__button {
  color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;
}

.widget-projects__cta__description.rtdcontent > * {
  font-size: 16px;
  line-height: 24px;
}

.widget-projects__cta__button {
  flex: 0 0 72px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  background-color: var(--color-theme-darker);
  font: bold 14px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.25s;
}

.widget-projects__cta__button::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f061";
  font-size: 13px;
  margin-right: 9px;
}

.widget-projects__cta__button:active,
.widget-projects__cta__button:hover {
  background-color: var(--color-theme-darker-hover);
}