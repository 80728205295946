html
{
  --pagegrid-rowsize-header-top: min-content;

  --resultslist-thumbnail-width: 200px;

  --widget-highlight-padding:           20px 25px;


/*
  --widget-cta-subtitle-color: var(--rtd-heading-color);
  --widget-cta-subtitle-font:  var(--rtd-text-subtitle-font);

  --widget-cta-sidebyside-padding-top:           34px;
  --widget-cta-sidebyside-padding-bottom:        34px;
  --widget-cta-sidebyside-inside-padding-top:    0;
  --widget-cta-sidebyside-inside-padding-bottom: 0;

  --widget-cta-stacked-inside-padding-top:    11px;
  --widget-cta-stacked-inside-padding-bottom: 25px;

  --widget-cta-sidebyside-radius: var(--contentpanels-radius-medium);
  --widget-cta-image-radius:      var(--contentpanels-radius-medium);
*/

  --generic-panel-padding-top:    32px;
  --generic-panel-padding-bottom: 32px;



  --widget-cta-sidebyside-pad-h: 30px;
  --widget-cta-sidebyside-pad-top: var(--generic-panel-padding-top);
  --widget-cta-sidebyside-pad-bottom: var(--generic-panel-padding-bottom);

  --widget-cta-stacked-pad-h:    15px;
  --widget-cta-stacked-pad-top: var(--generic-panel-padding-top);
  --widget-cta-stacked-pad-bottom: var(--generic-panel-padding-bottom);



  /************************************************************
   *
   *  Page components / Tabstrip
   */

  /* Tabstrip styled as list of tags/boxes next to eachother (from TUE BOOST!): */
  --component-tabstrip-background:      transparent;
  --component-tabstrip-height:          28px;
  --component-tabstrip-spacing:         20px;
  --component-tabstrip-font:            12px var(--rtd-heading-fontfamily);

  --component-tabstrip-item-padleft:    15px;
  --component-tabstrip-item-padright:   22px;

  --component-tabstrip-item-color:      #666666;
  --component-tabstrip-item-background: #FFFFFF;
  --component-tabstrip-item-border:     1px solid #C8C8C8;

  --component-tabstrip-item-hover-color:          #000000;
  --component-tabstrip-item-hover-background:     #FFF7F7;
  --component-tabstrip-item-hover-border:         1px solid var(--color-theme);

  --component-tabstrip-item-selected-color:       #FFFFFF;
  --component-tabstrip-item-selected-background:  var(--color-theme-darker);
  --component-tabstrip-item-selected-border:      none;
  --component-tabstrip-item-selected-underlining-color: var(--color-theme);
}

.pageheader-bottombar
{
  /************************************************************
   *
   *  Page components / Tabstrip
   */

  /* Tabstrip styled as list of tags/boxes next to eachother (from TUE BOOST!): */
  --component-tabstrip-background:                transparent;
  --component-tabstrip-height:                    50px;
  --component-tabstrip-spacing:                   55px;
  --component-tabstrip-font:                      bold 24px/26px var(--rtd-heading-fontfamily);

  --component-tabstrip-item-padleft:              0px;
  --component-tabstrip-item-padright:             0px;

  --component-tabstrip-item-color:                #666666;
  --component-tabstrip-item-background:           transparent;
  --component-tabstrip-item-border:               none; /*3px solid #000000;*/

  --component-tabstrip-item-hover-color:          var(--color-red);
  --component-tabstrip-item-hover-background:     none;
  --component-tabstrip-item-hover-border:         none; /* 1px solid var(--color-theme); */

  --component-tabstrip-item-selected-color:       #000000;
  --component-tabstrip-item-selected-background:  none;
  --component-tabstrip-item-selected-border:      none;
  --component-tabstrip-item-selected-font:        var(--component-tabstrip-font);
  --component-tabstrip-item-selected-underlining-color: #000000;
}





/* FIXME: target the specific tabs instead of using not !! */
  html:not(.page--folderwithfilters) .pagetabs--tabs a
, html:not(.page--folderwithfilters) .pagetabs--tabs span
, html:not(.page--folderwithfilters) .pagetabs--tabs label
{
  /*
  text-transform: uppercase;

  min-width: 115px;
  justify-content: center;
  */
}




.widget-links a
{
  padding-top: 1px;
  padding-bottom: 1px;

  text-transform: uppercase;
  font: bold 14px var(--rtd-heading-fontfamily);
}


html.page--folderwithfilters
{
/* ACOI */
  /*--component-tabstrip-background:   #ECE4EC;*/
  /*--component-tabstrip-background:   #F5F5F5;*/
  --component-tabstrip-background:   transparent;
  --component-tabstrip-height:       50px;
  --component-tabstrip-spacing:      25px;
  --component-tabstrip-font:         16px var(--rtd-text-fontfamily);

  --component-tabstrip-item-padleft:    10px;
  --component-tabstrip-item-padright:   22px;

  --component-tabstrip-item-color:      #636363;
  --component-tabstrip-item-background: transparent;
  --component-tabstrip-item-border:     none;

  --component-tabstrip-item-hover-background:     transparent;
  --component-tabstrip-item-hover-border:         none;
  --component-tabstrip-item-hover-color:          #000000;

  --component-tabstrip-item-selected-background:  none;
  --component-tabstrip-item-selected-border:      none;
  --component-tabstrip-item-selected-color:       var(--color-blue);
  --component-tabstrip-item-selected-underlining-color: var(--color-blue);
}




html.page--folderwithfilters .pagetabs
{
  margin-bottom: 25px;
}

html.page--folderwithfilters .pagetabs__content label::after
{
  bottom: -3px;
}

html.page--folderwithfilters .pagetabs__content > *:first-child + label
{
  padding-left: 0;
}

html.page--folderwithfilters .pagetabs__content > *:first-child + label::after
{
  left: 0;
}

html.page--folderwithfilters .pagetabs__content input:checked + label
{
  --component-tabstrip-font: bold 16px var(--rtd-text-fontfamily);
}

html.page--folderwithfilters .pagetabs--tabs .pagetabs__content
{
  border-bottom: 3px solid #CCCCCC;
}


/* ACOI
.acoi
{
  --component-tabstrip-background:   #ECE4EC;
  --component-tabstrip-height:       60px;
  --component-tabstrip-spacing:      25px;
  --component-tabstrip-font:         16px var(--rtd-text-fontfamily);

  --component-tabstrip-item-padleft:    10px;
  --component-tabstrip-item-padright:   22px;

  --component-tabstrip-item-color:      #636363;
  --component-tabstrip-item-background: transparent;
  --component-tabstrip-item-border:     none;

  --component-tabstrip-item-hover-color:          #000000;
  --component-tabstrip-item-hover-background:     transparent;
  --component-tabstrip-item-hover-border:         none;

  --component-tabstrip-item-selected-bordercolor: var(--color-theme);
  --component-tabstrip-item-selected-color:       var(--color-theme);
}
*/
