.spc-tiles--strip .spc-tile {
  text-decoration: none;
  padding: var(--component-spctile-padding-v) var(--component-spctile-padding-h);
  border-radius: var(--component-spctile-radius);
  margin-left: calc(var(--component-spctile-padding-h) * -1); /* align content visually - we only have a background-color on hover, so we need the padding to be outside the grid lines */
}
.spc-tiles--strip .spc-tile:first-child {
  /* force alignment (when not hovering/focussing) based on the inside content */
  margin-top: calc(-1 * var(--component-spctile-padding-v));
}
.spc-tiles--strip .spc-tile:focus {
  outline: 3px solid var(--color-theme);
  outline-offset: 2px; /* have some offset because the outline might not have a good contrast with all blocks */
  z-index: 3;
}
.spc-tiles--strip .spc-tile.notmatching {
  display: none !important;
}
.spc-tiles--strip a.spc-tile.spc-tile {
  text-decoration: none;
}
.spc-tiles--strip .spc-tile:hover {
  background: var(--component-spctile-hover-background);
}
.spc-tiles--strip .spc-tile:hover .spc-tile__image {
  transform: scale(1.05);
}
.spc-tiles--strip .spc-tile__top {
  grid-column: 1;
  grid-row: 1/span 2;
  flex: none;
  position: relative;
  z-index: 0; /* encapsulate z-index of content within */
  overflow: hidden;
  aspect-ratio: var(--gridimage-aspectratio);
  border: var(--component-spctile-image-border);
  border-radius: var(--component-spctile-image-border-radius);
  overflow: hidden;
}
.spc-tiles--strip .spc-tile__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  aspect-ratio: var(--gridimage-aspectratio);
  object-fit: cover;
  background-size: cover;
  transition: transform 0.15s ease-in-out, opacity 0.25s;
}
.spc-tiles--strip .spc-tile__meta {
  grid-column: 3;
  grid-row: 1;
  flex: 1 1 0;
  word-break: break-word; /* text must always wrap */
  z-index: 2;
}
.spc-tiles--strip .spc-tile__when {
  color: var(--component-spctile-when-color);
  font: var(--component-spctile-when-font);
  margin-bottom: 2px;
}
.spc-tiles--strip .spc-tile__title {
  color: var(--component-spctile-title-color);
  font: var(--component-spctile-title-font);
  text-transform: var(--component-spctile-title-text-transform);
  text-wrap: balance;
}
.spc-tiles--strip .spc-tile:hover .spc-tile__title {
  text-decoration: underline;
}
.spc-tiles--strip .spc-tile__description {
  grid-column: 3;
  grid-row: 2;
  color: var(--component-spctile-text-color);
  font: var(--component-spctile-text-font);
  --rtd-text-color: var(--component-spctile-text-color);
  --rtd-text-font: var(--component-spctile-text-font);
}
.spc-tiles--strip .spc-tile__description > * {
  /*
  This work on:
  - Chrome 14+  (sep 2011)
  - Edge 17+    (apr 2018)
  - Safari 5+   (mar 2012)
  - iOS 5+      (mar 2012)
  - Firefox 68+ (jul 2019)

  Also see
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102 - Support for -webkit-line-clamp
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992 - Support for a future max-lines property
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

@media (min-width: 500px) {
  .spc-tiles--strip.spc-tiles--withimages .spc-tile {
    display: grid;
    grid-template-columns: var(--component-spctile-image-listview-width) 30px 1fr;
    grid-template-rows: min-content 1fr;
  }
}
.spc-tiles--strip.spc-tiles--withimages .spc-tile__top {
  display: grid;
}
.spc-tiles--strip.spc-tiles--withimages .spc-tile__image, .spc-tiles--strip.spc-tiles--withimages .spc-tile__top::after {
  grid-column: 1;
  grid-row: 1;
}
.spc-tiles--strip.spc-tiles--withimages .spc-tile__top::after {
  content: "";
  background: radial-gradient(circle, rgba(0, 103, 180, 0) 25%, rgba(0, 103, 180, 0.2) 100%), radial-gradient(circle, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.5) 100%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.1s;
}
.spc-tiles--strip.spc-tiles--withimages .spc-tile:hover .spc-tile__top::after {
  content: "";
  opacity: 1;
}
.spc-tiles--strip.spc-tiles--withimages .spc-tile--noimage .spc-tile__top::after {
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.11) 150%), linear-gradient(100deg, #F4F4F4, #FFFFFF);
  opacity: 1;
}

.spc-tiles--strip.spc-tiles--noimages {
  /*
    .spc-tile
    {
      display: grid;
      grid-template-columns: min-content 30px 1fr;
      grid-template-rows: min-content 1fr;
    }

    .spc-tile::before
    {
      grid-column: 1;
      grid-row: 1;
      align-self: baseline;

      content: "";
      font: var(--fontawesome-solid);
      content: var(--fa-chevron-right);
      margin-right: 15px;
    }
  */
}
.spc-tiles--strip.spc-tiles--noimages .spc-tile__meta {
  align-self: baseline;
}