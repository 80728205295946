/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
.footer {
  background-color: #000000;
  color: #FFFFFF;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer input[name=footercolumn] {
  display: none;
}

.footer__column__heading {
  display: block;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  color: inherit;
  margin-bottom: 22px;
}

.footer__column1 {
  font-size: 16px;
  font-weight: bold;
}

.footer__column1 li + li {
  margin-top: 10px;
}

.footer__column2 .rtdcontent > *,
.footer__column3 .rtdcontent > * {
  color: #AAAAAA;
}

.footer__column2 .rtdcontent a,
.footer__column3 .rtdcontent a {
  color: inherit;
  font-weight: bold;
}

.footer__column1 ul,
.footer__column1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer__bottombar {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.footer__bottombar__copyright {
  margin-right: 25px;
  color: #9E9E9E;
}

.footer__bottombar__menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer__bottombar__menu li + li {
  margin-left: 20px;
}

.footer__socialitems {
  display: flex;
}

.footer__socialitem {
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}

.footer__socialitem svg {
  width: 22px;
  height: 22px;
}

a.footer__socialitem:focus,
a.footer__socialitem:hover {
  border-color: var(--color-red);
  color: var(--color-red);
}

.footer__socialitem + .footer__socialitem {
  margin-left: 16px;
}

.footer__socialitems + h3 {
  margin-top: 40px;
}

.footer__newslettersignup {
  position: relative;
  display: flex;
}

.footer__newslettersignup input {
  border: 1px solid #FFFFFF;
  background-color: #000000;
  color: #FFFFFF;
  width: 100%;
  height: 38px;
  padding-left: 11px;
  padding-right: 48px;
  font: 14px var(--rtd-text-fontfamily);
}
.footer__newslettersignup input::-webkit-input-placeholder {
  opacity: 1;
  color: #9E9E9E;
}
.footer__newslettersignup input::placeholder {
  opacity: 1;
  color: #9E9E9E;
}

.footer__newslettersignup__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: 48px;
  margin-left: -48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer__newslettersignup__submit__icon {
  color: #FFFFFF;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__bottombar__logo {
  margin-left: auto;
}
@media (max-width: 600px) {
  .footer__bottombar__logo {
    display: none;
  }
}

.footer__bottombar__logo > svg {
  width: 70px;
  height: 30px;
  color: #FFFFFF;
}

.footer__newslettersignup__success {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: opacity 0.25s;
}

html.subscribedtonewsletter .footer__newslettersignup {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
html.subscribedtonewsletter .footer__newslettersignup__success {
  visibility: visible;
  height: auto;
  opacity: 1;
  padding-top: 15px;
}

@media (max-width: 799px) {
  .footer {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .footer__column__heading {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
  .footer__column__heading--newsletter,
  .footer__bottombar {
    margin-top: 30px;
  }
  label.footer__column__heading::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f067";
    font-size: 16px;
    margin-right: 20px;
  }
  .footer__column__content {
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.5s;
  }
  input:checked ~ .footer__column__content {
    height: auto;
    opacity: 1;
    padding: 8px 0 5px 0;
  }
  .footer__column1 {
    display: none;
  }
  .footer__bottombar {
    border-top: 0;
  }
}
@media (min-width: 800px) and (max-width: 1023px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 50px;
    padding-bottom: 0;
  }
  .footer__column1,
  .footer__column2,
  .footer__column3,
  .footer__column4 {
    flex: 0 0 calc(50% - 30px);
    padding-bottom: 30px;
  }
  .footer__column1 {
    order: 1;
  }
  .footer__column2 {
    order: 3;
  }
  .footer__column3 {
    order: 4;
  }
  .footer__column4 {
    order: 2;
  }
  .footer__column__heading--newsletter {
    margin-top: 30px;
  }
  .footer__bottombar {
    border-top: 1px solid #666666;
    height: 60px;
  }
  .footer__bottombar {
    margin-top: 45px;
    order: 5;
    width: 100%;
  }
}
@media (min-width: 1024px) {
  .footer {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    padding-top: 50px;
    padding-bottom: 0;
  }
  .footer__column1 {
    grid-column: 3/span 5;
  }
  .footer__column2 {
    grid-column: 9/span 5;
  }
  .footer__column3 {
    grid-column: 15/span 5;
  }
  .footer__column4 {
    grid-column: 21/span 5;
  }
  .footer__bottombar {
    margin-top: 45px;
    grid-row: 2;
    grid-column: var(--pagegrid-col-verywide);
  }
  .footer__column__heading--newsletter {
    margin-top: 30px;
  }
  .footer__bottombar {
    border-top: 1px solid #666666;
    height: 60px;
  }
}