/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.projects__filters {
  padding-top: 30px;
  padding-bottom: 45px;
  color: #000000;
  grid-column: var(--pagegrid-col-fullwidth);
}

/* don't hide the main wrapper!!
   We need it to reserve the grid-row so don't accedently
   get another element (for example <dialog>) without explicit row
   filling and stretching this row. */
.projects__filters.filteredoverview__form {
  grid-column: var(--pagegrid-col-fullwidth);
  grid-row: 4;
}

html:not(.filteredoverview--alwaysopen):not(.filteredoverview--showfilters) .projects__filters {
  padding: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s;
}

html:not(.filteredoverview--alwaysopen):not(.filteredoverview--showfilters) .projects__filters__content {
  display: none;
}

.projects__filters label {
  cursor: pointer;
}

.projects__filters__label.projects__filters__label {
  align-self: center;
  font: 600 33px var(--rtd-heading-fontfamily);
  color: #000000;
  margin-bottom: 20px;
}

.filters__option {
  flex: none;
  display: flex;
}

.projects__filters__breakline {
  width: 100% !important;
  margin: 0;
}

@media (min-width: 1023px) {
  html.filteredoverview--showfilters .projects__filters,
  html.filteredoverview--alwaysopen .projects__filters {
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    opacity: 1;
    pointer-events: auto;
  }
  .projects__results__items,
  .projects__filters__content {
    grid-column: var(--pagegrid-col-verywide);
  }
  .projects__filters {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .projects__filters__status.projects__filters__status {
    flex: 1 0 auto;
    min-width: min-content;
    display: flex;
    flex-wrap: wrap;
    column-gap: 55px;
    row-gap: 25px;
    align-self: center;
    margin-bottom: 20px;
  }
}
@media (max-width: 1023px) {
  html.filteredoverview--showfilters .projects__filters,
  html.filteredoverview--alwaysopen .projects__filters {
    display: block !important;
    opacity: 1;
    pointer-events: auto;
    padding-left: 10px;
    padding-right: 10px;
  }
  .projects__filters {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .projects__filters__status.projects__filters__status {
    flex: 1 0 auto;
    min-width: min-content;
    display: grid;
    grid-template-columns: min-content 50px 1fr;
  }
  .projects__filters__status > *:nth-child(1) {
    grid-row: 1;
    grid-column: 1;
    margin-bottom: 15px;
  }
  .projects__filters__status > *:nth-child(2) {
    grid-row: 1;
    grid-column: 3;
  }
  .projects__filters__status > *:nth-child(3) {
    grid-row: 2;
    grid-column: 1;
  }
  .projects__filters__status > *:nth-child(4) {
    grid-row: 2;
    grid-column: 3;
  }
}