.widget-contentsummary
{
  --resultslist-thumbnail-width: 115px;

  grid-column: var(--pagegrid-col-fullwidth);

  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.contentsummary__itemcount
{
  color: var(--color-theme);
  font: bold 16px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;

  margin-bottom: 15px;
}

.widget-contentsummary h3
{
  grid-column: var(--pagegrid-col-fullwidth);
  padding-bottom: 5px;
  border-bottom: 1px solid #D3D3D3;
  text-transform: none;

  color: var(--color-blue);
  font: bold 16px/22px var(--rtd-heading-fontfamily);
}

.widget-contentsummary h3::before
{
  content: var(--fa-tag);
  font: var(--fontawesome-solid);
  font-size: 13px;

  margin-right: 10px;
}

.widget-contentsummary h3 + .folderwithfilters__items--listview
{
  --component-spctile-padding-v: 13px !important;
  margin-top: var(--component-spctile-padding-v);
}

.widget-contentsummary .folderwithfilters__items--listview + h3
{
  margin-top: 30px;
}




  .widget-richcontent__column .widget-contentsummary .contentsummary__itemcount
, .widget-richcontent__column .folderwithfilters__items--listview
{
  grid-column: var(--pagegrid-col-fullwidth);
}


.projectblock__viewlink
{
  margin-top: 6px;

  display: inline-flex;
  height: 28px;
  font: 12px/15px var(--rtd-text-fontfamily);
  text-transform: none;

  padding: 2px 14px;
}

.projectblock__viewlink::before
{
  content: var(--fa-arrow-right);
  font: var(--fontawesome-regular);
  font-size: 11px;
  margin-right: 10px;
}

.widget-contentsummary__items.folderwithfilters__items--listview .projectblock-title
{
  color: #000000;
}
