.page__headerfilters {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}

.page__headerfilters__filterbar {
  grid-column: var(--pagegrid-col-verywide);
}

.page__headerfilters__expandedfilters {
  grid-column: var(--pagegrid-col-verywide);
}

html:not(.filteredoverview--showfilters) .header-expandedfilters {
  display: none;
}

@media (min-width: 800px) {
  .filterform--openondesktop .header-filterbar__togglefilters {
    display: none;
  }
  .filterform--openondesktop.filterform--openondesktop .header-expandedfilters {
    display: grid;
  }
}
.filterform--alwaysopen .header-filterbar__togglefilters {
  display: none;
}

.filterform--alwaysopen.filterform--alwaysopen .header-expandedfilters {
  display: grid;
}

.header-filterbar__togglefilters__open {
  visibility: visible;
}

.header-filterbar__togglefilters__close {
  visibility: hidden;
}

html.filteredoverview--showfilters .header-filterbar__togglefilters__open {
  visibility: hidden;
}
html.filteredoverview--showfilters .header-filterbar__togglefilters__close {
  visibility: visible;
}
html.filteredoverview--showfilters .page__body .footer {
  display: none;
}