/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*
We enlarge column 4 because:
- we can still align content to the left making it align to the page content
- this allows an header with slideshow to extend a little
*/
.widget-news > h3 {
  grid-column: var(--pagegrid-col-narrow);
  margin-bottom: 25px;
}

a.widget-news__item {
  text-decoration: none;
}

.widget-news__item:hover .widget-news__item__title {
  color: var(--color-red);
  text-decoration: underline;
}

@media (max-width: 600px) {
  .widget-news {
    background-color: var(--color-lightgrey);
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .widget-news__item {
    display: flex;
    margin-bottom: 20px;
  }
  .widget-news__item__image {
    flex: none;
    display: block;
    width: 117px;
    height: 117px;
    background-size: cover;
    margin-right: 30px;
  }
  .widget-news__item__date,
  .widget-news__item__title {
    font: bold 16px/24px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    color: #000000;
  }
  .widget-news__item__description {
    margin-top: 2px;
    font: 15px/25px var(--rtd-text-fontfamily);
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}
@media (min-width: 600px) and (max-width: 799px) {
  .widget-news {
    grid-column: var(--pagegrid-col-fullwidth);
    z-index: 10;
    background-color: var(--color-lightgrey);
    padding-top: 32px;
    padding-bottom: 32px;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-news__items {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-news > h3 {
    grid-column: var(--pagegrid-col-verywide);
    margin-bottom: 25px;
  }
  .widget-news__item {
    display: block;
  }
  .widget-news__item__image {
    width: 100%;
    padding-top: 51.49%;
    background-size: cover;
    margin-bottom: 7px;
  }
  .widget-news__item__date,
  .widget-news__item__title {
    font: bold 16px/24px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    color: #000000;
  }
  .widget-news__item__description {
    margin-top: 2px;
    font: 15px/25px var(--rtd-text-fontfamily);
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .widget-news__item:nth-child(1),
  .widget-news__item:nth-child(3) {
    grid-column: var(--pagegrid-col-verywide-left);
  }
  .widget-news__item:nth-child(2),
  .widget-news__item:nth-child(4) {
    grid-column: var(--pagegrid-col-verywide-right);
  }
  .widget-news__item:nth-child(1),
  .widget-news__item:nth-child(2) {
    grid-row: 1;
  }
  .widget-news__item:nth-child(3),
  .widget-news__item:nth-child(4) {
    margin-top: 30px;
    grid-row: 2;
  }
  .widget-news__overviewlink {
    margin-top: 30px;
    grid-column: var(--pagegrid-col-verywide);
    grid-row: 3;
    justify-self: end;
  }
}
@media (min-width: 800px) {
  .widget-news {
    grid-column: var(--pagegrid-col-fullwidth);
    z-index: 10;
    background-color: var(--color-lightgrey);
    padding-top: 32px;
    padding-bottom: 32px;
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
  }
  .widget-news__items {
    grid-column: var(--pagegrid-col-fullwidth);
    display: grid;
    grid-template-columns: var(--pagegrid-template-columns);
    grid-template-rows: min-content min-content min-content min-content;
  }
  .widget-news > h3 {
    grid-column: var(--pagegrid-col-verywide);
    margin-bottom: 25px;
  }
  .widget-news__item:nth-child(1) {
    display: block;
    grid-column: 3/span 13;
    grid-row: 1/-1;
  }
  .widget-news__item:nth-child(1) .widget-news__item__image {
    width: 100%;
    padding-top: 51.49%;
    background-size: cover;
    margin-bottom: 7px;
  }
  .widget-news__item:nth-child(1) .widget-news__item__date {
    font: bold 16px/24px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    color: #000000;
  }
  .widget-news__item:nth-child(1) .widget-news__item__title {
    font: bold 24px/32px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    color: #000000;
  }
  .widget-news__item:nth-child(1) .widget-news__item__description {
    margin-top: 8px;
    font: 16px/24px var(--rtd-text-fontfamily);
    color: #666666;
  }
  .widget-news__item:nth-child(2),
  .widget-news__item:nth-child(3),
  .widget-news__item:nth-child(4) {
    display: flex;
    grid-column: 17/span 10;
    margin-bottom: 20px;
  }
  .widget-news__item:nth-child(2) .widget-news__item__image,
  .widget-news__item:nth-child(3) .widget-news__item__image,
  .widget-news__item:nth-child(4) .widget-news__item__image {
    flex: none;
    display: block;
    width: 117px;
    height: 117px;
    background-size: cover;
    margin-right: 30px;
  }
  .widget-news__item:nth-child(2) .widget-news__item__date, .widget-news__item:nth-child(2) .widget-news__item__title,
  .widget-news__item:nth-child(3) .widget-news__item__date,
  .widget-news__item:nth-child(3) .widget-news__item__title,
  .widget-news__item:nth-child(4) .widget-news__item__date,
  .widget-news__item:nth-child(4) .widget-news__item__title {
    font: bold 16px/24px var(--rtd-heading-fontfamily);
    text-transform: uppercase;
    color: #000000;
  }
  .widget-news__item:nth-child(2) .widget-news__item__description,
  .widget-news__item:nth-child(3) .widget-news__item__description,
  .widget-news__item:nth-child(4) .widget-news__item__description {
    margin-top: 2px;
    font: 15px/25px var(--rtd-text-fontfamily);
    color: #666666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .widget-news__item:nth-child(2) {
    grid-row: 1;
  }
  .widget-news__item:nth-child(3) {
    grid-row: 2;
  }
  .widget-news__item:nth-child(4) {
    grid-row: 3;
  }
  .widget-news__overviewlink {
    grid-column: 17/span 10;
    grid-row: 4;
    align-self: end;
    justify-self: end;
  }
}