.filteredoverview__feedbackandviewbar {
  grid-column: var(--pagegrid-col-verywide);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  font: 18px/30px var(--rtd-text-fontfamily);
  color: #767676;
}

.filteredoverview__feedback {
  font: bold 16px/24px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  color: #000000;
  margin-right: 15px;
}

.filteredoverview__feedbackandviewbar__content {
  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.filteredoverview__filtertags {
  display: none;
}

.filteredoverview--filtersactive .filteredoverview__filtertags {
  display: block;
}

.filtertags__title {
  flex: none;
}

.filtertags__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -7.5px;
  margin-bottom: -7.5px;
}

.filtertags__item {
  display: flex;
  align-items: center;
  height: 33px;
  font: 300 14px/33px var(--rtd-text-fontfamily);
  background-color: #FFFFFF;
  border: 1px solid #9E9E9E;
  color: #6F6F6F;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 15px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  cursor: pointer;
  max-width: 100%;
}

.filtertags__item:hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
}

.filtertags__item::after {
  color: #767676;
  margin-left: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00d";
  font-size: 14px;
}

.filtertags__item:hover::after {
  color: #000000;
}

.filtertags__item__remove {
  align-self: center;
  padding: 0 8px 0 8px;
  font-size: 9px;
}