/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/* Toggles (iOS switch style)
   https://developer.apple.com/design/human-interface-guidelines/ios/controls/switches/
*/
/**
Which iconfont to use for:
  - checkmarks
  - radio bullets
  - fileupload clouds
  - clear/remove crosses

Available options:
  - "fontawesome"
  - "ingenious"
  - "fallback" - Uses normal UTF-8 characters
  - ""
*/
.wh-form__dateinputgroup input,
.wh-form__timeinputgroup input {
  text-transform: uppercase;
}

.wh-form__buttongroup {
  margin-top: 30px;
  border-top: 1px solid #C5C5C5;
  padding-top: 25px;
}

:root {
  --wh-field-textinput-height: 44px;
  --wh-label-font-size: 16px;
  --wh-button-background-color: #C72125;
  --wh-button-border: 0 none;
  --wh-button-color: #fff;
  --wh-button-padding: 12px 15px;
  --wh-button-border-radius: 3px;
  --wh-button-height: 48px;
  --wh-field-font-size: 16px;
  --wh-field-padding: 12px 15px;
  --wh-field-border-radius: 3px;
  --wh-field-color: #979797;
  --wh-field-color--hover: inherit;
  --wh-field-color--focus: inherit;
  --wh-field-color--error: #D50000;
  --wh-field-color--disabled: #C5C5C5;
  --wh-field-border-color: #979797;
  --wh-field-border-color--hover: #979797;
  --wh-field-border-color--focus: #0067B4;
  --wh-field-border-color--error: #D50000;
  --wh-field-border-color--disabled: #F2F2F2;
  --wh-field-background-color: #fff;
  --wh-field-background-color--hover: #fff;
  --wh-field-background-color--focus: #fff;
  --wh-field-background-color--error: #fff;
  --wh-field-background-color--disabled: #ddd;
  --wh-field-border-box-shadow: none;
  --wh-field-border-box-shadow--hover: none;
  --wh-field-border-box-shadow--focus: 0 0 0 3px rgba(40, 52, 119, 0.15);
  --wh-field-border-box-shadow--error: none;
  --wh-field-border-box-shadow--disabled: none;
  --wh-imgedit-padding: 4px;
  --wh-imgedit-margin: 2px 3px;
  --wh-imgedit-width: 150px;
  --wh-imgedit-height: 200px;
  --wh-imgedit-explain-padding: 5px;
  --wh-imgedit-uploadicon-width: 50px;
  --wh-imgedit-uploadicon-height: 50px;
  --wh-imgedit-uploadicon-margin: 0 0 20px;
  --wh-fileedit-name-margin-right: 16px;
  --wh-fileedit-margin-top: 10px;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
html.dompack--busymodal .rpc__busyoverlay {
  display: block;
}
.rpc__busyoverlay .spinner {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 80px;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form.wh-styledinput {
  /*
    .wh-form__label
    {
      font-weight: bold;
    }
  */
  /*
  // Mark: fix om te voorkomen dat lange select options de layout oprekken
  select:not(.wh-rtd__toolbarstyle)
  {
    flex: 1 1 auto;
    width: 0;
  }
  */
  /* date/time */
  /*
  FILL WHOLE AREA

    .wh-form__buttongroup.wh-form__navbuttons > *
    {
      justify-content: center;
      flex: 1 0 auto;
    }
  */
}
.wh-form.wh-styledinput h3 {
  font-size: 21px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--nomargin {
  margin-bottom: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form.wh-styledinput .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form.wh-styledinput .wh-form__optionlabel, .wh-form.wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 200px;
  max-width: 200px;
  min-width: 200px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - 200px);
}
.wh-form.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
@media (max-width: 800px) {
  .wh-form.wh-styledinput .wh-form__fieldgroup {
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none;
    max-width: calc(100vw - 40px);
    min-width: 0;
  }
  .wh-form.wh-styledinput .wh-form__fields {
    width: 100%;
  }
}
.wh-form.wh-styledinput select + .wh-form__subfield > label,
.wh-form.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form.wh-styledinput input, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-form.wh-styledinput .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__timeinputgroup {
  padding: 0 15px 0 15px;
  max-width: 100%;
  flex: 1;
}
.wh-form.wh-styledinput input:focus, .wh-form.wh-styledinput select:not(.wh-rtd__toolbarstyle):focus,
.wh-form.wh-styledinput .wh-form__dateinputgroup:focus,
.wh-form.wh-styledinput .wh-form__timeinputgroup:focus {
  outline: none;
  box-shadow: 0 0 3px rgb(0, 150, 255);
}
.wh-form.wh-styledinput textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}
.wh-form.wh-styledinput textarea {
  min-height: 140px;
}
.wh-form.wh-styledinput .wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd__stylescope {
  padding: 5px 8px;
}
.wh-form.wh-styledinput .wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border-color: #aaa;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error input, .wh-form.wh-styledinput .wh-form__fieldgroup--error select,
.wh-form.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  background-color: #FFEAEA;
  border-color: #cd202c;
  color: #FF0000;
}
.wh-form.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #FF0000;
  padding: 8px 0 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup, .wh-form.wh-styledinput .wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup input, .wh-form.wh-styledinput .wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form.wh-styledinput .wh-form__dateinputgroup__line::after, .wh-form.wh-styledinput .wh-form__timeinputgroup__line::after {
  top: 12px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-form.wh-styledinput .wh-form__buttongroup {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
  min-width: 200px;
}
.wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--next, .wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button--submit {
  margin-left: auto;
}