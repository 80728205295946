/*

.sideMainMenu   - 100
.header-top     -  11
.header-menubar -  11
.page-header    - ...


@mixin overlap-deeplinks - 10
.deeplinks-wrapper       -  9

*/
/*

Purple (background) - #642F68
Purple (heading)    - #74237D

*/
.pagesidebar {
  --sidebar-heading-color: #000000;
  --sidebar-heading-font: var(--rtd-h3-font);
  --sidebar-links-font: 15px/18px var(--rtd-text-fontfamily);
}

.pagesidebar--tt {
  --sidebar-heading-color: #74237D;
  --sidebar-heading-font: bold 17px var(--rtd-heading-fontfamily);
  --sidebar-links-font: 16px/22px var(--rtd-text-fontfamily);
}

.pagesidebar h2 {
  color: var(--sidebar-heading-color);
  font: var(--sidebar-heading-font);
  margin-bottom: 10px;
}
.pagesidebar .projectdetails__meta {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
}
.pagesidebar .ttresource-sidebar__meta {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  background: #F9F9F9;
  border-top: 3px solid #74237D;
  padding: 15px 15px 25px 15px;
}
@media (max-width: 1024px) {
  .pagesidebar .projectdetails__meta {
    margin-top: 30px;
  }
}

/* Metadata at the right of the page content */
@media (min-width: 1024px) {
  .pagesidebar {
    margin-top: var(--whitespace-header-content);
    grid-column: 19/span 6;
    grid-row: 1/span 100;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
  }
  .pagetabs ~ .pagesidebar {
    grid-row: 2/span 100;
  }
}
/*****************************************************************************
*
*   Meta info / Contacts
*/
.pagesidebar .widget--persons__item {
  align-items: center;
}
.pagesidebar .widget--person__photo {
  border-radius: 50%;
  width: 80px;
  height: 80px;
}
.pagesidebar ul.project__contacts {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pagesidebar .person__spacer {
  max-width: 15px;
}
.pagesidebar .person__role {
  font-size: 10px;
}
.pagesidebar .person__fullname {
  font-size: 15px;
}
.pagesidebar .person__meta__contact {
  margin-top: 0;
  line-height: 125%;
}
.pagesidebar .project__contacts li + li {
  margin-top: 15px;
}

/*****************************************************************************
*
*   Meta info / Links
*/
.meta-item--links ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.meta-item--links ul li + li {
  margin-top: 10px;
}

.meta-item--links a {
  display: flex;
  align-items: baseline;
  color: var(--rtd-link-color);
  font: var(--sidebar-links-font);
  text-decoration: none;
  word-break: break-word;
}

.meta-item--links a:hover::before {
  font-weight: bold;
}

.meta-item--links a:hover .title {
  text-decoration: underline;
}

.meta-item--links a::before {
  flex: none;
  width: 21px;
  font: var(--fontawesome-regular);
  font-size: 15px;
  content: var(--fa-custom-internallink);
  color: var(--color-theme);
}

.meta-item--links a.isexternal::before {
  content: var(--fa-custom-externallink);
}

.meta-item--links a.isdownload::before {
  content: var(--fa-download);
}