html
{
  --rtd-heading-fontfamily: Gilroy,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  --rtd-text-fontfamily: Lato,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

  /*$font-titles:  Gilroy,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;*/
  --rtd-button-fontfamily: Gilroy,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;

  /* Headings & intro */
  --rtd-h1-font: bold 38px/46px var(--rtd-heading-fontfamily);
  --rtd-h2-font: bold 24px/26px var(--rtd-heading-fontfamily);
  --rtd-h3-font: bold 16px/24px var(--rtd-heading-fontfamily);
  --rtd-h4-font: bold 15px/20px var(--rtd-heading-fontfamily);;
  --widget-withbg-title-font: bold 34px/41px var(--rtd-heading-fontfamily);

  --widget-text-small: 15px/24px var(--rtd-text-fontfamily);

  --rtd-pageheader-text-font: 16px/28px var(--rtd-text-fontfamily);

/*
  --rtd-table-font:          17px/25px var(--rtd-heading-fontfamily);
  --rtd-table-heading-font: bold 17px/25px var(--rtd-heading-fontfamily);
  --rtd-h2-scaling-font:    bold clamp(25px,2vw,28px)/32px var(--rtd-heading-fontfamily);
*/

  --widget-links-icon-font: var(--fontawesome-regular);
  --widget-links-icon-size: 13px;

/*
  --widget-links-icon-font: var(--fontawesome-light);
  --widget-links-icon-size: 19px;
*/

  --rtd-heading-color: #000000;

  /* Paragraphs */
  --rtd-text-color:    #666666;
  --rtd-text-font: 16px/24px var(--rtd-text-fontfamily);

  --rtd-list-ul-marker-color: #C72125;
  --rtd-list-ol-marker-color: #C72125;

  --rtd-spacing-paragraph: 15px;




  --color-theme:              #C72125;
  --color-theme-darker:       #B31E21;
  --color-theme-darker-hover: #9D1A1D; /* darken(var(--color-theme-darker), 5%); */

  --color-red:          #C72125;
  --color-red-hover:    var(--color-theme-darker);
  --color-blue:         #0067B4;
  --color-blue-hover:   #0080E0;

  --color-purple:       #74237D;

  --color-lightgrey:    #F5F5F5;

  --background-blue-gradient: linear-gradient(90deg, #14143C 0%, #28286C 100%);
  --background-red-gradient:  linear-gradient(90deg, #7B3F3C 0%, #341D1C 100%);


  --fa-custom-internallink: var(--fa-arrow-right);
/*  --fa-custom-externallink: var(--fa-external-link-alt);*/
  --fa-custom-externallink: var(--fa-external-link-square);


  --button-color: var(--color-blue);
  --button-color-hover: var(--color-blue-hover);

  --button-outline-border-color: var(--color-red);
  --button-outline-text-color:   var(--color-red);
}

@media (max-width: 600px)
{
  html
  {
    --rtd-pageheader-text-font: 14px/20px var(--rtd-text-fontfamily);

    --rtd-h2-font: bold 23px/26px var(--rtd-heading-fontfamily);

    --widget-text-small: 15px/20px var(--rtd-text-fontfamily);
  }
}

/*@media (max-width: $minwidth-desktop-fontsizes - 1)*/
@media (max-width: 500px)
{
  html
  {
    --rtd-h1-font: bold 26px/30px var(--rtd-heading-fontfamily);
    --rtd-h2-font: bold 22px/26px var(--rtd-heading-fontfamily);
    --widget-withbg-title-font: bold 26px/30px var(--rtd-heading-fontfamily);
  }
/*
  h1
  , .pageheader__title
  {
    font-size: 26px;
    line-height: 30px;
  }
*/
}



.widget--background-lightgrey
{
  --currenttheme-background:    var(--color-lightgrey);
  --currenttheme-heading-color: #FFFFFF;
  --currenttheme-text-color:    #FFFFFF;
  --currenttheme-link-color:    #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;
}

.colortheme--red
{
  --currenttheme-background:    var(--color-red);
  --currenttheme-heading-color: #FFFFFF;
  --currenttheme-text-color:    #FFFFFF;
  --currenttheme-link-color:    #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;
}

.colortheme--blue
{
  --currenttheme-background:    var(--color-blue);
  --currenttheme-heading-color: #FFFFFF;
  --currenttheme-text-color:    #FFFFFF;
  --currenttheme-link-color:    #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;
}

.colortheme--bluegradient
{
  --currenttheme-background:    var(--background-blue-gradient);
  --currenttheme-heading-color: #FFFFFF;
  --currenttheme-text-color:    #FFFFFF;
  --currenttheme-link-color:    #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;
}

.colortheme--redgradient
{
  --currenttheme-background:    var(--background-red-gradient);
  --currenttheme-heading-color: #FFFFFF;
  --currenttheme-text-color:    #FFFFFF;
  --currenttheme-link-color:    #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;
}

.colortheme--blue
{
  --currenttheme-background:     #0067B4; /*var(--color-blue);*/
  --currenttheme-heading-color:  #FFFFFF;
  --currenttheme-text-color:     #FFFFFF;
  --currenttheme-link-color:     #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;l

/*  --currenttheme-text-on-bg-color:    #FFFFFF;*/
/*  --currenttheme-heading-on-bg-color: #FFFFFF;*/
}

.colortheme--purple
{
  --currenttheme-background: #74237D;
  --currenttheme-heading-color:  #FFFFFF;
  --currenttheme-text-color:     #FFFFFF;
  --currenttheme-link-color:     #FFFFFF;

  --button-outline-border-color: #FFFFFF;
  --button-outline-text-color:   #FFFFFF;
}
